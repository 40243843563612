import { CustomTranslateService } from '../services/custom-translate.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translateService: CustomTranslateService) {

  }

  transform(value: any): any {
    if (this.translateService.langJson[value]) {
      return this.translateService.langJson[value];
    }
    else {
      return value;
    }
  }

}
