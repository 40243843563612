import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable, Subject} from 'rxjs';
import {DMSConfigService} from './dmsconfig.service';

@Injectable({
  providedIn: 'root',
})
export class AdminLoginService {
  private customerId!: string;
  private readonly customerSelectionChanged = new Subject<boolean>();
  customerSelectionChanged$ = this.customerSelectionChanged.asObservable();

  constructor(private readonly oauthService: OAuthService,
              private readonly http: HttpClient,
              private readonly dmsConfigService: DMSConfigService) {
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  getCustomerId() {
    return this.customerId;
  }

  setCustomerCookie$(): Observable<void> {
    const authCustomerUrl = this.dmsConfigService.getConfig().API_GATEWAY_AUTHENTICATION_URL + `/${this.getCustomerId()}`;
    return this.http.get<void>(authCustomerUrl);
  }

  emitCustomerSelectionChanged(isSelected: boolean) {
    this.customerSelectionChanged.next(isSelected);
  }

}
