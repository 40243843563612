import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadDataService {
  private shouldReloadData = true;

  getShouldReload(): boolean {
    return this.shouldReloadData;
  }

  setShouldReload(value: boolean): void {
    this.shouldReloadData = value;
  }
}
