<div class="main-container">

  <div class="header-container">
    <dms-aio-header></dms-aio-header>
  </div>

  <div class="body-container">

    <div class="side-nav-container">
      <dms-aio-side-nav></dms-aio-side-nav>
    </div>

    <ng-template #customerSelected>

      <div class="module-container">

        <ng-container *ngIf="!showFilterPage">

          <div class="container" *ngIf="userDetailesLoaded">

            <mat-drawer-container autosize>
              <mat-drawer
                #panel
                mode="side"
                position="end"
                [ngClass]="{
                'mat-drawer-phone': !isWebView,
                'mat-drawer-web': isWebView
              }"
              >
                <i
                  class="modus-icons iconSize"
                  (click)="closePanel()"
                  aria-hidden="true"
                >close</i
                >
                <dms-file-detail *ngIf="selectedTab === 0"></dms-file-detail>
                <dms-unit-detail *ngIf="selectedTab === 1"></dms-unit-detail>
                <dms-group-detail *ngIf="selectedTab === 2"></dms-group-detail>
              </mat-drawer>

              <div class="document-div">

              <span class="document-header"
              >Documentation Management
                <i
                  class="modus-icons refresh-icon"
                  aria-hidden="true"
                  (click)="refresh()"
                >refresh</i
                ></span
              >
              </div>
              <mat-tab-group #tabGroup
                             (selectedTabChange)="onTabChange($event)"
                             headerPosition="{{ headerPosition }}"
                             [selectedIndex]="selectedTab"
                             [ngClass]="{
                'tab-mobile': !isWebView,
                'tab-web': isWebView
              }"
              >
                <mat-tab>
                  <ng-template mat-tab-label *ngIf="isWebView">
                    <span>{{ "fileName" | translate }}</span>
                  </ng-template>

                  <ng-template mat-tab-label *ngIf="!isWebView">
                    <div class="tab-icon-div">
                      <ng-container *ngIf="selectedTab === 0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-40 -114)">
                            <g transform="translate(-2027 -1144)">
                              <path
                                d="M11.846,19.238h7.788V17.787H11.846Zm0-4.112h7.788V13.675H11.846ZM9.451,23.35A1.488,1.488,0,0,1,8,21.9V5.451a1.392,1.392,0,0,1,.435-1.016A1.392,1.392,0,0,1,9.451,4h8.732l5.3,5.3V21.9a1.488,1.488,0,0,1-1.451,1.451Zm8.006-13.4h4.571l-4.571-4.5Z"
                                transform="translate(2063.138 1256.506)"
                                fill="#00437B"
                              />
                              <rect
                                width="24"
                                height="24"
                                transform="translate(2067 1258)"
                                fill="none"
                              />
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <ng-container *ngIf="selectedTab != 0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-40 -114)">
                            <g transform="translate(-2027 -1144)">
                              <path
                                d="M11.846,19.238h7.788V17.787H11.846Zm0-4.112h7.788V13.675H11.846ZM9.451,23.35A1.488,1.488,0,0,1,8,21.9V5.451a1.392,1.392,0,0,1,.435-1.016A1.392,1.392,0,0,1,9.451,4h8.732l5.3,5.3V21.9a1.488,1.488,0,0,1-1.451,1.451Zm8.006-13.4h4.571l-4.571-4.5Z"
                                transform="translate(2063.138 1256.506)"
                                fill="#fff"
                              />
                              <rect
                                width="24"
                                height="24"
                                transform="translate(2067 1258)"
                                fill="none"
                              />
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <span class="file-text">{{ "fileName" | translate }}</span>
                    </div>
                  </ng-template>
                  <div class="grid-filter" *ngIf="filterForm">
                    <form [formGroup]="filterForm">
                      <mat-error
                        *ngIf="filterForm.errors?.startDateGreaterThanEndDate"
                      >
                        {{ "startDateGreaterThanEndDate" | translate }}
                      </mat-error
                      >
                      <mat-error *ngIf="filterForm.errors?.timeExceed">
                        {{ "timeExceed" | translate }}
                      </mat-error
                      >
                      <mat-error *ngIf="filterForm.errors?.startDateNotSelected">
                        {{ "startDateNotSelected" | translate }}
                      </mat-error
                      >

                      <span class="search-span">
                      <modus-text-input
                        #searchInput
                        label="{{ 'search' | translate }}"
                        type="text"
                        include-search-icon
                        class="icon-size"
                        (mousedown)="$event.stopPropagation()"
                        (keydown)="$event.stopPropagation()"
                        (valueChange)="applyFilter(searchInput.value)"
                        placeholder="{{ 'fileDisplayName' | translate }}"
                        clearable="true"
                        value={{searchFilterValue}}
                      >
                      </modus-text-input>
                    </span>
                      <ng-container *ngIf="isWebView; else filterButton">
                      <span class="datepicker-span-start">
                        <modus-date-picker>
                          <modus-date-input
                            #startDateInput
                            type="start"
                            label="{{ 'startDate' | translate }}"
                            format="dd-mm-yyyy"
                            allowed-chars-regex="[\d-]"
                            show-calendar-icon="true"
                            placeholder="{{ 'selectStartDate' | translate }}"
                            (valueChange)="
                              onStartDateChanged(startDateInput.value)
                            "
                          ></modus-date-input>
                        </modus-date-picker>
                      </span>

                        <span class="datepicker-span">
                        <modus-date-picker>
                          <modus-date-input
                            #endDateInput
                            type="end"
                            label="{{ 'endDate' | translate }}"
                            format="dd-mm-yyyy"
                            allowed-chars-regex="[\d-]"
                            show-calendar-icon="true"
                            placeholder="{{ 'selectEndDate' | translate }}"
                            (valueChange)="onEndDateChanged(endDateInput.value)"
                          ></modus-date-input>
                        </modus-date-picker>
                      </span>

                        <span class="reset-style"><modus-button
                          (buttonClick)="onResetClick()"
                          button-style="outline"
                          color="primary"
                        >{{ "reset" | translate }}</modus-button
                        ></span>

                      </ng-container>
                    </form>
                  </div>

                  <ng-template #filterButton>
                      <span class="filter-style">
                        <button class="filter-button" (click)="filter()">
                          <img
                            src="../../../../assets/images/filter.svg"
                            class="filter-img"
                          />
                        </button>
                      </span>
                  </ng-template>
                  <div class="upload-style">
                    <input
                      type="file"
                      #fileUpload
                      id="fileUpload"
                      name="fileUpload"
                      multiple="multiple"
                      style="display: none"
                    />
                    <ng-container
                      *ngIf="isWebView; else uploadFloatingButton"
                    >
                      <modus-button
                        color="primary"
                        class="uploadButton"
                        (buttonClick)="onUploadClick()"
                        [disabled]="inProgress"
                      >
                        <i class="modus-icons upload-icon" aria-hidden="true"
                        >upload</i
                        >
                        {{ "uploadFiles" | translate }}
                      </modus-button>
                    </ng-container>
                    <ng-template #uploadFloatingButton>
                        <span class="upload-mobile">
                          <button
                            class="uploadButtonMobile"
                            (click)="onUploadClick()"
                            [disabled]="inProgress"
                          >
                            <i class="modus-icons" aria-hidden="true"
                            >add_bold</i
                            >
                          </button>
                        </span>
                    </ng-template>
                  </div>
                  <div class="table-div">
                    <dms-file [filterValue]="filterValue" #fileView [startDateFilter]="startDateFilter"
                              [endDateFilter]="endDateFilter" [pageNumber]="pageNumber"></dms-file>
                  </div>
                </mat-tab>
                <mat-tab [disabled]="disableTab">
                  <ng-template mat-tab-label *ngIf="isWebView">
                    <span>{{ "name" | translate }}</span>
                  </ng-template>

                  <ng-template mat-tab-label *ngIf="!isWebView">
                    <div class="tab-icon-div">
                      <ng-container
                        *ngIf="selectedTab === 1"
                        class="active-tab-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-40 -211)">
                            <g transform="translate(-2150.295 -1041.295)">
                              <rect
                                width="24"
                                height="24"
                                transform="translate(2190.295 1252.295)"
                                fill="none"
                              />
                              <path
                                d="M6.518,23.691a2.892,2.892,0,0,1-2.9-2.9H2V9.473a1.414,1.414,0,0,1,.442-1.031A1.414,1.414,0,0,1,3.473,8H17.691v4.1H20.27l3.34,4.445v4.248H21.866a2.9,2.9,0,0,1-4.948,2.05,2.793,2.793,0,0,1-.847-2.05H9.416a2.893,2.893,0,0,1-2.9,2.9Zm0-1.473a1.424,1.424,0,1,0-1.007-2.431,1.424,1.424,0,0,0,1.007,2.431Zm12.45,0a1.425,1.425,0,1,0-1.007-.417A1.372,1.372,0,0,0,18.968,22.218Zm-1.277-5.009h4.567l-2.726-3.634H17.691Z"
                                transform="translate(2189.646 1248.114)"
                                fill="#00437B"
                              />
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <ng-container *ngIf="selectedTab != 1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-40 -211)">
                            <g transform="translate(-2150.295 -1041.295)">
                              <rect
                                width="24"
                                height="24"
                                transform="translate(2190.295 1252.295)"
                                fill="none"
                              />
                              <path
                                d="M6.518,23.691a2.892,2.892,0,0,1-2.9-2.9H2V9.473a1.414,1.414,0,0,1,.442-1.031A1.414,1.414,0,0,1,3.473,8H17.691v4.1H20.27l3.34,4.445v4.248H21.866a2.9,2.9,0,0,1-4.948,2.05,2.793,2.793,0,0,1-.847-2.05H9.416a2.893,2.893,0,0,1-2.9,2.9Zm0-1.473a1.424,1.424,0,1,0-1.007-2.431,1.424,1.424,0,0,0,1.007,2.431Zm12.45,0a1.425,1.425,0,1,0-1.007-.417A1.372,1.372,0,0,0,18.968,22.218Zm-1.277-5.009h4.567l-2.726-3.634H17.691Z"
                                transform="translate(2189.646 1248.114)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <span class="file-text">{{ "name" | translate }}</span>
                    </div>
                  </ng-template>
                  <dms-unit #unitView></dms-unit>
                </mat-tab>
                <mat-tab [disabled]="disableTab">
                  <ng-template mat-tab-label *ngIf="isWebView">
                    <span>{{ "groupName" | translate }}</span>
                  </ng-template>
                  <ng-template mat-tab-label *ngIf="!isWebView">
                    <div class="tab-icon-div">
                      <ng-container *ngIf="selectedTab === 2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27.886"
                          height="19.809"
                          viewBox="0 0 27.886 19.809"
                        >
                          <g transform="translate(-36.057 -208.867)">
                            <g transform="translate(-805 51.838)">
                              <g transform="translate(55.417 2.528)">
                                <g transform="translate(801.526 157.712)">
                                  <rect
                                    width="11.654"
                                    height="10.262"
                                    transform="translate(0.183 2.42)"
                                    fill="#fff"
                                  />
                                  <g transform="translate(0 0)">
                                    <path
                                      d="M10.319,14.989a1,1,0,0,1-.867-.5c-.112-.2-.127-.326-.127-1.07v-.694H2.675v.707a3.712,3.712,0,0,1-.04.845.955.955,0,0,1-.273.445,1,1,0,0,1-.679.268.987.987,0,0,1-.894-.565l-.077-.162L.705,13.49.7,12.723H0l0-1.955L.013,8.813.75,8.368c.369-.226.749-.459.765-.477a10.88,10.88,0,0,0,.108-1.208c.049-.742.093-1.236.117-1.322a1.23,1.23,0,0,1,.972-.885c.112-.016,1.827-.024,3.464-.024,1.459,0,2.979.007,3.086.021a1.252,1.252,0,0,1,1.01.925c.015.063.063.653.1,1.288a10.642,10.642,0,0,0,.108,1.2c.016.018.4.251.765.477l.738.445L12,10.768l0,1.955h-.7l-.008.767-.007.771-.077.162A.985.985,0,0,1,10.319,14.989ZM1.655,9.338a.829.829,0,0,0,0,1.657l.061,0a.852.852,0,0,0,.71-.517.831.831,0,0,0-.772-1.138Zm8.681,0a.826.826,0,0,0-.8.639.832.832,0,0,0,.807,1.019.83.83,0,0,0,.29-1.606A.889.889,0,0,0,10.336,9.336ZM2.755,5.048l-.132.07a.668.668,0,0,0-.32.375c-.014.047-.05.486-.087,1.068-.032.487-.07,1.044-.078,1.143l-.015.17H9.88L9.865,7.7c-.008-.092-.045-.609-.08-1.143a9.294,9.294,0,0,0-.1-1.088.6.6,0,0,0-.315-.36l-.13-.065H2.755Zm9.222,3.12h0c-.009,0-.152-.089-.333-.2l-.174-.106-.485-.3-.058-.928c-.029-.5-.067-1.008-.077-1.135a1.776,1.776,0,0,0-1.5-1.578C9.234,3.906,7.532,3.9,6,3.9s-3.234.009-3.35.028a1.7,1.7,0,0,0-.99.51,1.371,1.371,0,0,0-.315.43,2.109,2.109,0,0,0-.155.413c-.032.15-.165,1.918-.165,2.2,0,.08-.014.091-.493.382-.332.2-.5.3-.513.308C.008,8.141,0,6.543.008,4.531c0-3.3.009-3.654.047-3.762A1.142,1.142,0,0,1,.9.023C.96.013,3.292,0,5.988,0H7.922c3.241,0,3.265.013,3.457.111L11.4.123a1.405,1.405,0,0,1,.435.415l.028.045c.121.193.121.193.126,3.16v.787c.005,2.2,0,3.623-.015,3.638Z"
                                      transform="translate(0 0)"
                                      fill="#00437B"
                                    />
                                  </g>
                                </g>
                                <g transform="translate(785.64 157.712)">
                                  <rect
                                    width="11.688"
                                    height="10.731"
                                    transform="translate(0.165 2.18)"
                                    fill="#fff"
                                  />
                                  <g transform="translate(0 0)">
                                    <path
                                      d="M10.319,14.989a1,1,0,0,1-.867-.5c-.112-.2-.127-.326-.127-1.07v-.694H2.675v.707a3.712,3.712,0,0,1-.04.845.955.955,0,0,1-.273.445,1,1,0,0,1-.679.268.987.987,0,0,1-.894-.565l-.077-.162L.705,13.49.7,12.723H0l0-1.955L.013,8.813.75,8.368c.369-.226.749-.459.765-.477a10.88,10.88,0,0,0,.108-1.208c.049-.742.093-1.236.117-1.322a1.23,1.23,0,0,1,.972-.885c.112-.016,1.827-.024,3.464-.024,1.459,0,2.979.007,3.086.021a1.252,1.252,0,0,1,1.01.925c.015.063.063.653.1,1.288a10.642,10.642,0,0,0,.108,1.2c.016.018.4.251.765.477l.738.445L12,10.768l0,1.955h-.7l-.008.767-.007.771-.077.162A.985.985,0,0,1,10.319,14.989ZM1.655,9.338a.829.829,0,0,0,0,1.657l.061,0a.852.852,0,0,0,.71-.517.831.831,0,0,0-.772-1.138Zm8.681,0a.826.826,0,0,0-.8.639.832.832,0,0,0,.807,1.019.83.83,0,0,0,.29-1.606A.889.889,0,0,0,10.336,9.336ZM2.755,5.048l-.132.07a.668.668,0,0,0-.32.375c-.014.047-.05.486-.087,1.068-.032.487-.07,1.044-.078,1.143l-.015.17H9.88L9.865,7.7c-.008-.092-.045-.609-.08-1.143a9.294,9.294,0,0,0-.1-1.088.6.6,0,0,0-.315-.36l-.13-.065H2.755Zm9.222,3.12h0c-.009,0-.152-.089-.333-.2l-.174-.106-.485-.3-.058-.928c-.029-.5-.067-1.008-.077-1.135a1.776,1.776,0,0,0-1.5-1.578C9.234,3.906,7.532,3.9,6,3.9s-3.234.009-3.35.028a1.7,1.7,0,0,0-.99.51,1.371,1.371,0,0,0-.315.43,2.109,2.109,0,0,0-.155.413c-.032.15-.165,1.918-.165,2.2,0,.08-.014.091-.493.382-.332.2-.5.3-.513.308C.008,8.141,0,6.543.008,4.531c0-3.3.009-3.654.047-3.762A1.142,1.142,0,0,1,.9.023C.96.013,3.292,0,5.988,0H7.922c3.24,0,3.265.013,3.457.111L11.4.123a1.405,1.405,0,0,1,.435.415l.028.045c.121.193.121.193.126,3.16v.787c.005,2.2,0,3.623-.015,3.638Z"
                                      transform="translate(0 0)"
                                      fill="#00437B"
                                    />
                                  </g>
                                </g>
                                <g transform="translate(791.64 154.501)">
                                  <rect
                                    width="15.886"
                                    height="11.347"
                                    transform="translate(0 4.306)"
                                    fill="#fff"
                                  />
                                  <g transform="translate(0 0)">
                                    <path
                                      d="M13.637,19.809a1.324,1.324,0,0,1-1.146-.662c-.147-.259-.168-.431-.168-1.414v-.918H3.535v.934a4.905,4.905,0,0,1-.052,1.117,1.262,1.262,0,0,1-.36.588,1.324,1.324,0,0,1-.9.354,1.3,1.3,0,0,1-1.181-.747l-.1-.215-.01-1.018-.01-1.014H0l.006-2.584.01-2.584.975-.588c.488-.3.99-.606,1.01-.63a14.379,14.379,0,0,0,.143-1.6c.065-.981.123-1.634.155-1.748a1.625,1.625,0,0,1,1.285-1.17C3.732,5.894,6,5.883,8.162,5.883c1.928,0,3.937.009,4.079.028a1.655,1.655,0,0,1,1.335,1.223c.02.083.083.863.139,1.7a14.063,14.063,0,0,0,.143,1.592c.021.024.522.332,1.01.63l.975.588.01,2.584.006,2.584h-.922l-.01,1.014-.01,1.018-.1.215A1.3,1.3,0,0,1,13.637,19.809ZM2.187,12.34a1.1,1.1,0,0,0,0,2.19c.027,0,.054,0,.081,0a1.126,1.126,0,0,0,.938-.683,1.1,1.1,0,0,0-1.021-1.5Zm11.473,0a1.092,1.092,0,0,0-1.059.845,1.1,1.1,0,0,0,1.067,1.346,1.1,1.1,0,0,0,.384-2.122A1.175,1.175,0,0,0,13.66,12.338ZM3.641,6.672l-.175.092a.882.882,0,0,0-.423.5c-.018.062-.066.642-.116,1.411-.043.644-.092,1.379-.1,1.51L2.8,10.4H13.057l-.02-.224c-.01-.122-.06-.8-.106-1.51A12.283,12.283,0,0,0,12.8,7.233a.793.793,0,0,0-.416-.476l-.172-.086H3.641Zm12.188,4.123h0c-.012,0-.2-.118-.44-.264l-.23-.14L14.517,10l-.076-1.226c-.039-.661-.088-1.332-.1-1.5a2.348,2.348,0,0,0-1.983-2.085c-.153-.025-2.4-.037-4.427-.037s-4.274.012-4.427.037a2.251,2.251,0,0,0-1.309.674,1.812,1.812,0,0,0-.416.569,2.787,2.787,0,0,0-.205.546c-.043.2-.218,2.534-.218,2.907,0,.106-.018.12-.651.505-.438.268-.66.4-.677.407C.01,10.758,0,8.647.01,5.987.017,1.623.022,1.159.073,1.015A1.509,1.509,0,0,1,1.183.03C1.269.017,4.351,0,7.913,0h2.556c4.282,0,4.315.017,4.568.146l.032.016a1.857,1.857,0,0,1,.575.549l.037.06c.16.255.16.255.166,4.177l0,1.04c.007,2.9,0,4.788-.019,4.807Z"
                                      transform="translate(0)"
                                      fill="#00437B"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <ng-container *ngIf="selectedTab != 2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27.886"
                          height="19.809"
                          viewBox="0 0 27.886 19.809"
                        >
                          <g transform="translate(-36.057 -208.867)">
                            <g transform="translate(-805 51.838)">
                              <g transform="translate(55.417 2.528)">
                                <g transform="translate(801.526 157.712)">
                                  <rect
                                    width="11.654"
                                    height="10.262"
                                    transform="translate(0.183 2.42)"
                                    fill="#00437b"
                                  />
                                  <g transform="translate(0 0)">
                                    <path
                                      d="M10.319,14.989a1,1,0,0,1-.867-.5c-.112-.2-.127-.326-.127-1.07v-.694H2.675v.707a3.712,3.712,0,0,1-.04.845.955.955,0,0,1-.273.445,1,1,0,0,1-.679.268.987.987,0,0,1-.894-.565l-.077-.162L.705,13.49.7,12.723H0l0-1.955L.013,8.813.75,8.368c.369-.226.749-.459.765-.477a10.88,10.88,0,0,0,.108-1.208c.049-.742.093-1.236.117-1.322a1.23,1.23,0,0,1,.972-.885c.112-.016,1.827-.024,3.464-.024,1.459,0,2.979.007,3.086.021a1.252,1.252,0,0,1,1.01.925c.015.063.063.653.1,1.288a10.642,10.642,0,0,0,.108,1.2c.016.018.4.251.765.477l.738.445L12,10.768l0,1.955h-.7l-.008.767-.007.771-.077.162A.985.985,0,0,1,10.319,14.989ZM1.655,9.338a.829.829,0,0,0,0,1.657l.061,0a.852.852,0,0,0,.71-.517.831.831,0,0,0-.772-1.138Zm8.681,0a.826.826,0,0,0-.8.639.832.832,0,0,0,.807,1.019.83.83,0,0,0,.29-1.606A.889.889,0,0,0,10.336,9.336ZM2.755,5.048l-.132.07a.668.668,0,0,0-.32.375c-.014.047-.05.486-.087,1.068-.032.487-.07,1.044-.078,1.143l-.015.17H9.88L9.865,7.7c-.008-.092-.045-.609-.08-1.143a9.294,9.294,0,0,0-.1-1.088.6.6,0,0,0-.315-.36l-.13-.065H2.755Zm9.222,3.12h0c-.009,0-.152-.089-.333-.2l-.174-.106-.485-.3-.058-.928c-.029-.5-.067-1.008-.077-1.135a1.776,1.776,0,0,0-1.5-1.578C9.234,3.906,7.532,3.9,6,3.9s-3.234.009-3.35.028a1.7,1.7,0,0,0-.99.51,1.371,1.371,0,0,0-.315.43,2.109,2.109,0,0,0-.155.413c-.032.15-.165,1.918-.165,2.2,0,.08-.014.091-.493.382-.332.2-.5.3-.513.308C.008,8.141,0,6.543.008,4.531c0-3.3.009-3.654.047-3.762A1.142,1.142,0,0,1,.9.023C.96.013,3.292,0,5.988,0H7.922c3.241,0,3.265.013,3.457.111L11.4.123a1.405,1.405,0,0,1,.435.415l.028.045c.121.193.121.193.126,3.16v.787c.005,2.2,0,3.623-.015,3.638Z"
                                      transform="translate(0 0)"
                                      fill="#fff"
                                    />
                                  </g>
                                </g>
                                <g transform="translate(785.64 157.712)">
                                  <rect
                                    width="11.688"
                                    height="10.731"
                                    transform="translate(0.165 2.18)"
                                    fill="#00437b"
                                  />
                                  <g transform="translate(0 0)">
                                    <path
                                      d="M10.319,14.989a1,1,0,0,1-.867-.5c-.112-.2-.127-.326-.127-1.07v-.694H2.675v.707a3.712,3.712,0,0,1-.04.845.955.955,0,0,1-.273.445,1,1,0,0,1-.679.268.987.987,0,0,1-.894-.565l-.077-.162L.705,13.49.7,12.723H0l0-1.955L.013,8.813.75,8.368c.369-.226.749-.459.765-.477a10.88,10.88,0,0,0,.108-1.208c.049-.742.093-1.236.117-1.322a1.23,1.23,0,0,1,.972-.885c.112-.016,1.827-.024,3.464-.024,1.459,0,2.979.007,3.086.021a1.252,1.252,0,0,1,1.01.925c.015.063.063.653.1,1.288a10.642,10.642,0,0,0,.108,1.2c.016.018.4.251.765.477l.738.445L12,10.768l0,1.955h-.7l-.008.767-.007.771-.077.162A.985.985,0,0,1,10.319,14.989ZM1.655,9.338a.829.829,0,0,0,0,1.657l.061,0a.852.852,0,0,0,.71-.517.831.831,0,0,0-.772-1.138Zm8.681,0a.826.826,0,0,0-.8.639.832.832,0,0,0,.807,1.019.83.83,0,0,0,.29-1.606A.889.889,0,0,0,10.336,9.336ZM2.755,5.048l-.132.07a.668.668,0,0,0-.32.375c-.014.047-.05.486-.087,1.068-.032.487-.07,1.044-.078,1.143l-.015.17H9.88L9.865,7.7c-.008-.092-.045-.609-.08-1.143a9.294,9.294,0,0,0-.1-1.088.6.6,0,0,0-.315-.36l-.13-.065H2.755Zm9.222,3.12h0c-.009,0-.152-.089-.333-.2l-.174-.106-.485-.3-.058-.928c-.029-.5-.067-1.008-.077-1.135a1.776,1.776,0,0,0-1.5-1.578C9.234,3.906,7.532,3.9,6,3.9s-3.234.009-3.35.028a1.7,1.7,0,0,0-.99.51,1.371,1.371,0,0,0-.315.43,2.109,2.109,0,0,0-.155.413c-.032.15-.165,1.918-.165,2.2,0,.08-.014.091-.493.382-.332.2-.5.3-.513.308C.008,8.141,0,6.543.008,4.531c0-3.3.009-3.654.047-3.762A1.142,1.142,0,0,1,.9.023C.96.013,3.292,0,5.988,0H7.922c3.24,0,3.265.013,3.457.111L11.4.123a1.405,1.405,0,0,1,.435.415l.028.045c.121.193.121.193.126,3.16v.787c.005,2.2,0,3.623-.015,3.638Z"
                                      transform="translate(0 0)"
                                      fill="#fff"
                                    />
                                  </g>
                                </g>
                                <g transform="translate(791.64 154.501)">
                                  <rect
                                    width="15.886"
                                    height="11.347"
                                    transform="translate(0 4.306)"
                                    fill="#00437b"
                                  />
                                  <g transform="translate(0 0)">
                                    <path
                                      d="M13.637,19.809a1.324,1.324,0,0,1-1.146-.662c-.147-.259-.168-.431-.168-1.414v-.918H3.535v.934a4.905,4.905,0,0,1-.052,1.117,1.262,1.262,0,0,1-.36.588,1.324,1.324,0,0,1-.9.354,1.3,1.3,0,0,1-1.181-.747l-.1-.215-.01-1.018-.01-1.014H0l.006-2.584.01-2.584.975-.588c.488-.3.99-.606,1.01-.63a14.379,14.379,0,0,0,.143-1.6c.065-.981.123-1.634.155-1.748a1.625,1.625,0,0,1,1.285-1.17C3.732,5.894,6,5.883,8.162,5.883c1.928,0,3.937.009,4.079.028a1.655,1.655,0,0,1,1.335,1.223c.02.083.083.863.139,1.7a14.063,14.063,0,0,0,.143,1.592c.021.024.522.332,1.01.63l.975.588.01,2.584.006,2.584h-.922l-.01,1.014-.01,1.018-.1.215A1.3,1.3,0,0,1,13.637,19.809ZM2.187,12.34a1.1,1.1,0,0,0,0,2.19c.027,0,.054,0,.081,0a1.126,1.126,0,0,0,.938-.683,1.1,1.1,0,0,0-1.021-1.5Zm11.473,0a1.092,1.092,0,0,0-1.059.845,1.1,1.1,0,0,0,1.067,1.346,1.1,1.1,0,0,0,.384-2.122A1.175,1.175,0,0,0,13.66,12.338ZM3.641,6.672l-.175.092a.882.882,0,0,0-.423.5c-.018.062-.066.642-.116,1.411-.043.644-.092,1.379-.1,1.51L2.8,10.4H13.057l-.02-.224c-.01-.122-.06-.8-.106-1.51A12.283,12.283,0,0,0,12.8,7.233a.793.793,0,0,0-.416-.476l-.172-.086H3.641Zm12.188,4.123h0c-.012,0-.2-.118-.44-.264l-.23-.14L14.517,10l-.076-1.226c-.039-.661-.088-1.332-.1-1.5a2.348,2.348,0,0,0-1.983-2.085c-.153-.025-2.4-.037-4.427-.037s-4.274.012-4.427.037a2.251,2.251,0,0,0-1.309.674,1.812,1.812,0,0,0-.416.569,2.787,2.787,0,0,0-.205.546c-.043.2-.218,2.534-.218,2.907,0,.106-.018.12-.651.505-.438.268-.66.4-.677.407C.01,10.758,0,8.647.01,5.987.017,1.623.022,1.159.073,1.015A1.509,1.509,0,0,1,1.183.03C1.269.017,4.351,0,7.913,0h2.556c4.282,0,4.315.017,4.568.146l.032.016a1.857,1.857,0,0,1,.575.549l.037.06c.16.255.16.255.166,4.177l0,1.04c.007,2.9,0,4.788-.019,4.807Z"
                                      transform="translate(0)"
                                      fill="#fff"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <span class="file-text">{{ "groupName" | translate }}</span>
                    </div>
                  </ng-template>
                  <dms-group #groupView></dms-group>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label *ngIf="isWebView">
                    <span>{{ "incomingDocuments" | translate }}</span>
                  </ng-template>
                  <ng-template mat-tab-label *ngIf="!isWebView">
                    <div class="tab-icon-div">
                      <ng-container class="inc-doc" *ngIf="selectedTab === 3">
                        <svg
                          class="inc-doc"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-40 -209)">
                            <g transform="translate(-2150.2 -1043.2)">
                              <rect
                                width="24"
                                height="24"
                                transform="translate(2190.2 1252.2)"
                                fill="none"
                              />
                              <g transform="translate(2192.844 1254.397)">
                                <path
                                  d="M35.789,36.477,32.2,32.888l1.032-1.057,1.819,1.819V29.2h1.475v4.45l1.819-1.819,1.033,1.057Z"
                                  transform="translate(-20.668 -16.871)"
                                  fill="#00437B"
                                />
                                <path
                                  d="M10.441,19.569H1.457a1.4,1.4,0,0,1-1.02-.44A1.418,1.418,0,0,1,0,18.1V1.468A1.419,1.419,0,0,1,.437.44,1.4,1.4,0,0,1,1.457,0h8.765L15.54,5.357v5.406h-5.1v8.805ZM2.889,13.943V15.41H8.765V13.943H2.889Zm0-4.159v1.468H8.765V9.784H2.889Zm6.6-8.317h0v4.55h4.589L9.494,1.468Z"
                                  transform="translate(0 0)"
                                  fill="#00437B"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <ng-container *ngIf="selectedTab != 3">
                        <svg
                          class="inc-doc"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g transform="translate(-40 -209)">
                            <g transform="translate(-2150.2 -1043.2)">
                              <rect
                                width="24"
                                height="24"
                                transform="translate(2190.2 1252.2)"
                                fill="none"
                              />
                              <g transform="translate(2192.844 1254.397)">
                                <path
                                  d="M35.789,36.477,32.2,32.888l1.032-1.057,1.819,1.819V29.2h1.475v4.45l1.819-1.819,1.033,1.057Z"
                                  transform="translate(-20.668 -16.871)"
                                  fill="#fff"
                                />
                                <path
                                  d="M10.441,19.569H1.457a1.4,1.4,0,0,1-1.02-.44A1.418,1.418,0,0,1,0,18.1V1.468A1.419,1.419,0,0,1,.437.44,1.4,1.4,0,0,1,1.457,0h8.765L15.54,5.357v5.406h-5.1v8.805ZM2.889,13.943V15.41H8.765V13.943H2.889Zm0-4.159v1.468H8.765V9.784H2.889Zm6.6-8.317h0v4.55h4.589L9.494,1.468Z"
                                  transform="translate(0 0)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </ng-container>
                      <span class="file-text">{{
                          "incomingDocuments" | translate
                        }}</span>
                    </div>
                  </ng-template>
                  <dms-incoming-documents
                    *ngIf="selectedTab === 3"
                    #incomingDocumentsView (showFilterPage)="setFilterPage($event)"
                    [startDateFilter]="startDateFilter" [endDateFilter]="endDateFilter"
                  ></dms-incoming-documents>
                </mat-tab>
              </mat-tab-group>
            </mat-drawer-container>
          </div>
          <modus-spinner
            *ngIf="!userDetailesLoaded"
            class="mainSpinner"
            color="tertiary"
          >
          </modus-spinner>
          <div class="progress-diaolog" *ngIf="showDialog">
            <div class="upload-header">
              <div
                *ngIf="failedCount === 0 && !uploadSuccess"
                class="header-text"
              >
                {{ "upload" | translate }} {{ files.length }}
                {{ "files" | translate }}
              </div>
              <div *ngIf="failedCount === 0 && uploadSuccess" class="header-text">
              <span *ngIf="successCount === 1; else multiUploadSuccess">
                {{ files.length }} {{ "singleUploadSuccess" | translate }}
              </span>
                <ng-template #multiUploadSuccess>
                <span>
                  {{ files.length }} {{ "uploadSuccess" | translate }}
                </span>
                </ng-template>
              </div>
              <div *ngIf="failedCount > 0" class="header-text">
              <span *ngIf="failedCount > 1; else singleUploadfailed">
                {{ failedCount }} {{ "uploadFailed" | translate }}
              </span>
                <ng-template #singleUploadfailed>
                <span>
                  {{ failedCount }} {{ "singleUploadFailed" | translate }}
                </span>
                </ng-template>
              </div>
              <div class="right-icons">
                <div>
                <span
                  *ngIf="!minimized"
                  class="sortDownIcon"
                  (click)="minimizeDialog()"
                >
                  <fa-icon [icon]="['fas', 'sort-down']"></fa-icon>
                </span>
                  <span
                    *ngIf="minimized"
                    class="sortUpIcon"
                    (click)="minimizeDialog()"
                  >
                  <fa-icon [icon]="['fas', 'sort-up']"></fa-icon>
                </span>
                </div>
                <div class="upload-close">
                <span
                  [ngClass]="{
                    closeIcon: !getInProgress(),
                    'disable-icon': getInProgress()
                  }"
                  (click)="closeProgresssDialog()"
                >
                <i class="modus-icons small-icon" aria-hidden="true">close</i>
                </span>
                </div>
              </div>
            </div>
            <div class="files-progress" [ngStyle]="{ 'height.px': dialogHeight }">
              <ul>
                <li *ngFor="let file of files">
                  <div class="doc-line">
                    <div class="doc-name">
                    <span
                      id="file-label"
                      class="name-text"
                      title="{{ file.data.name }}"
                    >
                      {{ file.data.name }}
                    </span>
                    </div>
                    <div class="progess-icon">
                      <div *ngIf="file.alert">
                      <span class="alertIcon">
                        <i class="modus-icons small-icon" aria-hidden="true"
                           [title]="errorWithSizeTranslation">alert</i>
                      </span>
                      </div>
                      <mat-progress-spinner
                        *ngIf="!file.success && !file.failure && !file.alert"
                        color="primary"
                        mode="determinate"
                        [value]="file.progress"
                        diameter="25"
                        strokeWidth="3.5"
                      >
                      </mat-progress-spinner>
                      <div *ngIf="file.success">
                      <span class="successIcon">
                        <i class="modus-icons small-icon" aria-hidden="true" title="{{ 'success' | translate }}">check_circle</i>
                      </span>
                      </div>
                      <div *ngIf="file.failure">
                      <span class="failedIcon">
                        <i class="modus-icons small-icon" aria-hidden="true" title="{{ 'failed' | translate }}">cancel_circle</i>
                      </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="showFilterPage">
          <dms-date-filter [startDateFilter]="startDateFilter" [endDateFilter]="endDateFilter"
                           [filterValue]="filterValue"
                           [isIncomingDocTab]="isIncomingDocTab" (closeEvent)="closeFilterPage($event)"
                           (filterValueEvent)="filterValueEvent($event)"></dms-date-filter>
        </ng-container>
      </div>

    </ng-template>

    <div *ngIf="customerNotSelected else customerSelected"
         class="customer-not-selected-banner">
      <span>{{ 'select.customer.message' | translate }}</span>
    </div>

  </div>

</div>
