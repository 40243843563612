export class TidUserProfile {
  tidEmail: string;
  givenName: string;
  familyName: string;
  tidFullName: string;
  uuid: string;
  language: string;
  timeZone: string;
}

export class CloudUserProfile {
  trn: string;
  uuid: string;
  deleted: boolean;
  createdTimeStamp: string;
  createdBy: string;
  updatedBy: string;
  updatedTimeStamp: string;
  primaryRegion: string;
  firstName: string;
  lastName: string;
  lastLoginTimeStamp: string;
  language: string;
  dataRegion: string;
  timezone: string;
  email: string;
  country: string;
}
