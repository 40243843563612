import {Link, Type} from '../models/link.model';
import {ConfigurationGroup} from '../models/configuration-group.model';
import {Terminal} from 'src/app/models/terminal.model';
import {DeviceType} from '../enums/devicetype.enum';
import {FileMetadata} from '../models/file-metadata.model';
import {Menu} from '../document/shared/table/table-config/table-config';
import {Observable} from 'rxjs';
import {saveAs} from 'file-saver';
import {map} from 'rxjs/operators';

export const MIME_TYPES = {
  'jpg': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'png': 'image/png',
  'pdf': 'application/pdf',
};

export default class Utils {
  constructor() {
  }

  static readonly IMAGE_FORMATS = ['jpeg', 'jpg', 'png', 'gif'];
  static readonly VIDEO_FORMATS = ['avi', 'mp4'];
  static readonly ACTION_DOWNLOAD = 'Download';
  static readonly ACTION_PREVIEW = 'Preview';
  static readonly ACTION_DELETE = 'Delete';

  static getUniqueConfigGroups(terminals: Terminal[]) {
    const uniqueConfigGroups: ConfigurationGroup[] = [];
    terminals.forEach((item) => {
      const index = uniqueConfigGroups.findIndex(
        (value) => value.id === item.configurationGroup.id
      );
      if (index < 0) {
        uniqueConfigGroups.push(item.configurationGroup);
      }
    });
    return this.getNumberOfTerminalsForGroup(
      uniqueConfigGroups,
      terminals
    );
  }

  static getNumberOfTerminalsForGroup(uniqueConfigGroups, terminals) {
    const unitCountMap = new Map<string, number>();
    terminals.forEach((element) => {
      if (unitCountMap.get(element.configurationGroup.id) == null) {
        unitCountMap.set(element.configurationGroup.id, 1);
      } else {
        unitCountMap.set(
          element.configurationGroup.id,
          unitCountMap.get(element.configurationGroup.id) + 1
        );
      }
    });
    uniqueConfigGroups.map((element) => {
      element.unitCount = unitCountMap.get(element.id);
      element.groupName = element.name;
    });
    return uniqueConfigGroups;
  }

  static formatTerminals(terminals: Terminal[]) {
    const type = DeviceType;
    const formattedTerminals: Terminal[] = [];
    terminals.forEach((terminal) => {
      if (terminal.assetType !== 'TRAILER') {
        if (terminal.configurationGroup) {
          terminal.groupName = terminal.configurationGroup.name;
        }
        if (terminal.device) {
          terminal.deviceType = type[terminal.device.devicetype];
        }
        formattedTerminals.push(terminal);
      }
    });
    return formattedTerminals;
  }

  static formatFiles(fileMetaData: FileMetadata[]) {
    if (fileMetaData && fileMetaData.length > 0) {
      fileMetaData.forEach((file: FileMetadata) => {
        file.createdDate = this.getModifiedDate(file.createdDate);
        file.formattedFileSize = this.bytesToSize(file.fileSize);
        let iconName = '';
        if (file.fileType) {
          iconName = file.fileType.toLowerCase();
        }
        switch (iconName) {
          case 'pdf': {
            file.iconName = 'file_type_pdf';
            break;
          }
          case 'jpeg':
          case 'jpg':
          case 'png':
          case 'gif': {
            file.iconName = 'image';
            break;
          }
          case 'avi':
          case 'mp4': {
            file.iconName = 'video';
            break;
          }
          default: {
            file.iconName = 'file';
            break;
          }
        }
      });
    }
    return fileMetaData;
  }

  static getModifiedDate(dateStr: string): string {
    if (!isNaN(Number(dateStr))) {
      const date = new Date(Number(dateStr));

      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    } else {
      const dateParts = dateStr.split('/');
      if (
        (dateParts.length === 3 &&
          dateParts.every((part) => !isNaN(Number(part))))
      ) {
        return dateStr;
      }
    }
  }

  static getUnassignedUnitDetails(
    terminals: Terminal[],
    linkedDetails: Link[]
  ) {
    const newList = [];
    if (terminals && terminals.length > 0) {
      terminals.forEach((terminal: Terminal) => {
        const index = linkedDetails.findIndex(
          (data) => data.owner.id === terminal.terminalId
        );
        if (index < 0) {
          newList.push(terminal);
        }
      });
    }
    return newList;
  }

  static getUnassignedGroupDetails(
    configGroups: ConfigurationGroup[],
    linkedDetails: Link[]
  ) {
    const newList = [];
    if (configGroups && configGroups.length > 0) {
      configGroups.forEach((configGroup: ConfigurationGroup) => {
        const index = linkedDetails.findIndex(
          (data) => data.owner.id === configGroup.id
        );
        if (index < 0) {
          newList.push(configGroup);
        }
      });
    }
    return newList;
  }

  static getAssignedFileDetails(
    fileMetadata: FileMetadata[],
    linkedDetails: Link[],
    panelType
  ) {
    const assignedDetails: FileMetadata[] = [];
    if (linkedDetails && linkedDetails.length > 0) {
      linkedDetails.forEach((link: Link) => {
        let isAssignDetailUpdate = false;
        const details = new FileMetadata();
        const fileData = fileMetadata.filter(
          (data) => data.fileKey === link.document.id
        )[0];
        assignedDetails.forEach((data) => {
          if (data.fileKey === link.document.id) {
            isAssignDetailUpdate = true;
            data.assignedAt = data.assignedAt + ',' + link.owner.type;
            return;
          }
        });
        if (fileData && !isAssignDetailUpdate) {
          details.fileName = fileData?.fileName;
          details.fileSize = fileData?.fileSize;
          details.createdDate = fileData?.createdDate;
          details.fileKey = fileData?.fileKey;
          details.fileType = fileData?.fileType;
          details.linkId = link.id;
          details.assignedAt = link.owner.type;
          if (
            (link.owner.type === Type.GROUP ||
              link.owner.type === Type.CUSTOMER) &&
            panelType === 'unitpanel'
          ) {
            details.isCheckboxDisabled = true;
          } else if (
            link.owner.type === Type.CUSTOMER &&
            panelType === 'grouppanel'
          ) {
            details.isCheckboxDisabled = true;
          }
          assignedDetails.push(details);
        }
      });
    }
    return Utils.formatFiles(assignedDetails);
  }

  static getUnassignedFileDetails(
    fileMetadata: FileMetadata[],
    linkedDetails: Link[]
  ) {
    const newList = [];
    if (fileMetadata && fileMetadata.length > 0) {
      fileMetadata.forEach((fileData: FileMetadata) => {
        const index = linkedDetails.findIndex(
          (data) => data.document.id === fileData.fileKey
        );
        if (index < 0) {
          newList.push(fileData);
        }
      });
    }
    return Utils.formatFiles(newList);
  }

  static bytesToSize(bytes) {
    if (bytes === 0) {
      return '0 B';
    }
    const value = 1000,
      decimalPoint = 1,
      sizes = ['B', 'KB', 'MB'],
      index = Math.floor(Math.log(bytes) / Math.log(value));
    return (
      parseFloat((bytes / Math.pow(value, index)).toFixed(decimalPoint)) +
      ' ' +
      sizes[index]
    );
  }

  static isPreviewable(fileType: string): boolean {
    return this.isImage(fileType) || fileType === 'pdf';
  }

  static isImage(fileType: string): boolean {
    return this.IMAGE_FORMATS.includes(fileType);
  }

  static isVideo(fileType: string): boolean {
    return this.VIDEO_FORMATS.includes(fileType);
  }

  static buildDownloadAction(): Menu {
    return this.buildMenu(this.ACTION_DOWNLOAD, 'download_line', '#00437B');
  }

  static buildPreviewAction(): Menu {
    return this.buildMenu(this.ACTION_PREVIEW, 'visibility_on', '#00437B');
  }

  static buildDeleteAction(): Menu {
    return this.buildMenu(this.ACTION_DELETE, 'delete', '#d11a2a');
  }

  static buildMenu(menuName: string, iconName: string, iconColor: string): Menu {
    const menu = new Menu();
    menu.menuName = menuName;
    menu.iconName = iconName;
    menu.iconColor = iconColor;
    return menu;
  }

  static handlePreview$(fileData: Observable<Blob>, fileName: string): Observable<void> {
    return fileData.pipe(
      map((data) => {
        const mimeType = Utils.getMimeType(fileName.split('.').pop().toLowerCase(), data.type);
        Utils.openFile(data, mimeType, fileName);
      })
    );
  }

  static getMimeType(fileType: string, defaultType: string): string {
    return MIME_TYPES[fileType] || defaultType;
  }

  static openFile(data: Blob, mimeType: string, fileName: string): void {
    if (mimeType === 'application/octet-stream' || mimeType === 'application/zip') {
      saveAs(new Blob([data], {type: mimeType}), fileName);
    } else {
      window
        .open(
          window.URL.createObjectURL(new Blob([data], {type: mimeType})),
          '_blank'
        )
        .focus();
    }
  }

  static handleDownload$(fileData: Observable<Blob>, fileName: string): Observable<void> {
    return fileData.pipe(
      map((data) => {
        saveAs(new Blob([data], {type: data.type}), fileName);
      })
    );
  }

}
