import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { LoginService } from "./services/login.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private readonly loginService: LoginService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    const url: string = state.url;
    return this.checkLoggedIn(url);
  }

  checkLoggedIn(url: string): boolean {
    if (this.loginService.isSessionValid()) {
      return true;
    } else {
      this.loginService.setNavigatedUrl(url);
      this.loginService.login();
      return false;
    }
  }
}
