import {AfterViewInit, Component} from '@angular/core';

@Component({
  selector: 'dms-aio-side-nav',
  templateUrl: './aio-side-nav.component.html',
})
export class AioSideNavComponent implements AfterViewInit {

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setMenuAttributes();
    });
  }

  private setMenuAttributes(): void {
    const sideMenu = document.querySelector('ttl-side-menu');
    const menuItems = {
      name: 'DMS',
      iconSrc: '../../assets/images/dms.svg',
      isActive: 'true',
    };
    sideMenu?.setAttribute('menu-items', JSON.stringify([menuItems]));
  }

}
