import {ConfigurationGroup} from '../../../models/configuration-group.model';
import {AssignedDetailsForFile} from '../../../models/assigned-details-for-file';
import {TerminalService} from '../../../services/terminal.service';
import {Document, Link, Owner, Type} from '../../../models/link.model';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {TableConfig} from '../../shared/table/table-config/table-config';
import {RightPanelService} from '../../../services/right-panel.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FileMetadata} from 'src/app/models/file-metadata.model';
import {Subscription} from 'rxjs';
import {Terminal} from 'src/app/models/terminal.model';
import Utils from 'src/app/utils/utils';
import {TableComponent} from '../../shared/table/table.component';
import {HandleErrorService} from '../../../services/handle-error.service';
import {CustomTranslateService} from '../../../services/custom-translate.service';

@Component({
  selector: 'dms-file-detail',
  templateUrl: './file-detail.component.html',
})
export class FileDetailComponent implements OnInit, OnDestroy {
  @ViewChild('assignedTable') assignedTable: TableComponent;
  @ViewChild('unassignedUnitTable') unassignedUnitTable: TableComponent;
  @ViewChild('unassignedGroupTable') unassignedGroupTable: TableComponent;

  assignedTableConfig = new TableConfig();
  unassignedUnitTableConfig = new TableConfig();
  unassignedGroupTableConfig = new TableConfig();
  assignedTableData: any[] = null;
  unassignedUnitTableData: any[] = null;
  unassignedGroupTableData: any[] = null;
  fileData: FileMetadata;
  subscriptions: Subscription[] = [];
  terminals: Terminal[];
  configGroups: ConfigurationGroup[];

  constructor(
    private readonly rightPanelService: RightPanelService,
    private readonly docMgmtService: DocMgmtService,
    private readonly terminalService: TerminalService,
    private readonly handleErrorService: HandleErrorService,
    private readonly translateService: CustomTranslateService
  ) {
  }

  ngOnInit(): void {
    /** assigned table */
    this.assignedTableConfig.contentHeight = 70;
    this.assignedTableConfig.headerCheckbox = true;
    // this.assignedTableConfig.displayedColumnNames = ['assignedTo', 'type', 'lastOpened', 'by']; // planned for next release
    this.assignedTableConfig.displayedColumnNames = ['assignedTo', 'type'];
    this.assignedTableConfig.multipleSelection = true;
    this.assignedTableConfig.showCustomSearchField = true;
    /**unassigned unit table */
    this.unassignedUnitTableConfig.contentHeight = 70;
    this.unassignedUnitTableConfig.headerCheckbox = true;
    this.unassignedUnitTableConfig.displayedColumnNames = [
      'name',
      'deviceType',
    ];
    this.unassignedUnitTableConfig.multipleSelection = true;
    this.unassignedUnitTableConfig.showCustomSearchField = true;
    /**unassigned group table */
    this.unassignedGroupTableConfig.contentHeight = 70;
    this.unassignedGroupTableConfig.headerCheckbox = true;
    this.unassignedGroupTableConfig.displayedColumnNames = [
      'groupName',
      'unitCount',
    ];
    this.unassignedGroupTableConfig.multipleSelection = true;
    this.unassignedGroupTableConfig.showCustomSearchField = true;
    this.subscriptions.push(
      this.terminalService.terminals.subscribe((value) => {
        if (value) {
          this.terminals = value;
        }
      })
    );
    this.subscriptions.push(
      this.terminalService.configurationGroups.subscribe((value) => {
        if (value) {
          this.configGroups = value;
        }
      })
    );
    this.subscriptions.push(
      this.rightPanelService.selectedFile.subscribe((value: FileMetadata) => {
        if (value && Object.keys(value).length !== 0) {
          this.loadRightPanel(value);
        } else {
          this.fileData = null;
        }
      })
    );
  }

  async loadRightPanel(value) {
    await this.rightPanelService.openPanel();
    this.tableClearSelection();
    this.fileData = value;
    this.assignedTableData = null;
    this.unassignedUnitTableData = null;
    this.unassignedGroupTableData = null;
    this.loadTableData(value.fileKey);
  }

  loadTableData(fileKey) {
    this.docMgmtService.getAssignDetails(fileKey, null, null).subscribe(
      (links: Link[]) => {
        this.assignedTableData = this.getAssignedDetailsOfFile(links);
        this.unassignedUnitTableData = Utils.getUnassignedUnitDetails(
          this.terminals,
          links
        );
        this.unassignedGroupTableData = Utils.getUnassignedGroupDetails(
          this.configGroups,
          links
        );
      },
      (error) => {
        this.assignedTableData = [];
        this.unassignedUnitTableData = [];
        this.unassignedGroupTableData = [];
      }
    );
  }

  getAssignedDetailsOfFile(links): any[] {
    const assignedDetais: AssignedDetailsForFile[] = [];
    links.forEach((link: Link) => {
      const details = new AssignedDetailsForFile();
      if (link.owner.type === Type.UNIT) {
        const terminal = this.terminals.filter(
          (data) => data.terminalId === link.owner.id
        )[0];
        if (terminal) {
          details.linkId = link.id;
          details.ownerId = terminal?.terminalId;
          details.assignedTo = terminal?.name;
          details.type = Type.UNIT;
          assignedDetais.push(details);
        }
      }
      if (link.owner.type === Type.GROUP) {
        const configGroup = this.configGroups.filter(
          (data) => data.id === link.owner.id
        )[0];
        if (configGroup) {
          details.linkId = link.id;
          details.ownerId = configGroup?.id;
          details.assignedTo = configGroup?.name;
          details.type = Type.GROUP;
          assignedDetais.push(details);
        }
      }
    });
    return assignedDetais;
  }

  async unassignOwner() {
    if (this.assignedTable && this.assignedTable.getSelection()?.length > 0) {
      for (let i = 0; i < this.assignedTable.getSelection().length; i++) {
        await this.docMgmtService.unAssignDocument(
          this.assignedTable.getSelection()[i].linkId
        );
      }
      this.loadRightPanel(this.fileData);
    } else {
      this.handleErrorService.displayErrorDialog(
        this.translateService.getTranslatedValue('selectUnitsGroups'),
        ''
      );
    }
  }

  async assignUnit() {
    if (
      this.unassignedUnitTable &&
      this.unassignedUnitTable.getSelection()?.length > 0
    ) {
      for (
        let row = 0;
        row < this.unassignedUnitTable.getSelection().length;
        row++
      ) {
        const selectedUnit: Terminal =
          this.unassignedUnitTable.getSelection()[row];
        const link = new Link();
        link.document = new Document();
        link.document.id = this.fileData.fileKey;
        link.document.name = this.fileData.fileName;
        link.document.ext = this.fileData.fileType;
        const owner: Owner = new Owner();
        owner.customerNr = null;
        owner.type = Type.UNIT;
        owner.id = selectedUnit.terminalId;
        link.owner = owner;
        await this.docMgmtService.assignDocument(link);
      }
      this.loadRightPanel(this.fileData);
    } else {
      this.handleErrorService.displayErrorDialog(
        this.translateService.getTranslatedValue('selectUnits'),
        ''
      );
    }
  }

  async assignGroup() {
    if (
      this.unassignedGroupTable &&
      this.unassignedGroupTable.getSelection()?.length > 0
    ) {
      for (
        let row = 0;
        row < this.unassignedGroupTable.getSelection().length;
        row++
      ) {
        const selectedGroup: ConfigurationGroup =
          this.unassignedGroupTable.getSelection()[row];
        const link = new Link();
        link.document = new Document();
        link.document.id = this.fileData.fileKey;
        link.document.name = this.fileData.fileName;
        link.document.ext = this.fileData.fileType;
        const owner: Owner = new Owner();
        owner.customerNr = null;
        owner.type = Type.GROUP;
        owner.id = selectedGroup.id;
        link.owner = owner;
        await this.docMgmtService.assignDocument(link);
      }
      this.loadRightPanel(this.fileData);
    } else {
      this.handleErrorService.displayErrorDialog(
        this.translateService.getTranslatedValue('selectGroups'),
        ''
      );
    }
  }

  tableClearSelection() {
    this.assignedTable?.clearSelection();
    this.unassignedUnitTable?.clearSelection();
    this.unassignedGroupTable?.clearSelection();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
