<div *ngIf="terminalData; else noRowSelected" class="panel-container">

  <div class="detailPanel">
    <span class="detailPanelHeading">
      {{ "unitDisplayName" | translate }} :
    </span>
    <span class="detailPanelValue" title="{{ terminalData.name }}">
      {{ terminalData.name }}
    </span>
  </div>

  <div class="panel-content">
    <div class="panel-table-view">
      <div class="panel-table-title">

        <span class="panel-table-titleText panelHeading">
          {{ "assignedFiles" | translate }}
        </span>

        <span class="button-span">
          <modus-button
            (buttonClick)="unassignDocument()"
            button-style="outline"
            color="primary"
          >
            <img
              src="../../../../assets/images/unassign.svg"
              class="assign-unassign-icon"
              alt="Unassign Icon"
            />
            <span class="assign-unassign-button">
              {{ "unassign" | translate }}
            </span>
          </modus-button>
        </span>

      </div>

      <div class="panel-table-data">
        <dms-table
          [tableConfig]="assignedTableConfig"
          [tableData]="assignedTableData"
          #assignedTable
        >
        </dms-table>
      </div>

    </div>
  </div>

  <div class="panel-content">
    <div class="panel-table-view">
      <div class="panel-table-title">

        <span class="panel-table-titleText panelHeading">
          {{ "unassignedFiles" | translate }}
        </span>

        <span class="button-span">
          <modus-button
            (buttonClick)="assignDocument()"
            button-style="outline"
            color="primary"
          >
            <img
              src="../../../../assets/images/assign.svg"
              class="assign-unassign-icon"
              alt="Assign Icon"
            />
            <span class="assign-unassign-button">
              {{ "assign" | translate }}
            </span>
          </modus-button>
        </span>

      </div>

      <div class="panel-table-data">
        <dms-table
          [tableConfig]="unassignedTableConfig"
          [tableData]="unassignedTableData"
          #unassignedTable
        >
        </dms-table>
      </div>

    </div>
  </div>
</div>

<ng-template #noRowSelected>
  <div class="panelNoData">
    {{ "noRowSelected" | translate }}
  </div>
</ng-template>
