import {HttpHeaders} from '@angular/common/http';
import {AfterViewInit, Component} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {AdminLoginService} from '../services/admin-login.service';
import {LogoutService} from '../services/logout.service';
import {of, switchMap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TidUserProfile} from '../models/user-profile.model';
import {DMSConfigService} from '../services/dmsconfig.service';
import {UserService} from '../services/user.service';

const DEFAULT_LANGUAGE = 'en';
const DMS = 'DMS';

@Component({
  selector: 'dms-aio-header',
  templateUrl: './aio-header.component.html'
})
export class AioHeaderComponent implements AfterViewInit {

  constructor(
    private readonly authService: OAuthService,
    private readonly oauthService: OAuthService,
    private readonly adminLoginService: AdminLoginService,
    private readonly logoutService: LogoutService,
    private readonly dmsConfigService: DMSConfigService,
    private readonly userService: UserService
  ) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setHeaderAttributes();
    });
  }

  private setHeaderAttributes(): void {
    const tidUserProfile: TidUserProfile = this.userService.tidUserProfile.getValue();
    const header = document.querySelector('ttl-header');
    if (header) {
      header.setAttribute('language', tidUserProfile?.language || DEFAULT_LANGUAGE);
      header.setAttribute('app-key', DMS);
      header.setAttribute('header-title', DMS);
      header.setAttribute('env', this.dmsConfigService.getConfig().APP_ENVIRONMENT);
      if (this.authService.getAccessToken()) {
        header.setAttribute('access-token', this.authService.getAccessToken());
      }
      if (tidUserProfile) {
        header.setAttribute('user', JSON.stringify({
          name: tidUserProfile.tidFullName,
          email: tidUserProfile.tidEmail,
        }));
      }
    }
  }

  protected onHeaderEventFire(e: Event) {
    const customEvent = <CustomEvent>e;
    if (customEvent.detail.action === 'logOut') {
      this.handleLogout();
    } else if (customEvent.detail.action === 'customerSelectionChanged') {
      this.handleCustomerSelectionChange(customEvent.detail.value);
    }
  }

  private handleLogout() {
    let headers = new HttpHeaders();
    headers = headers.set(
      'Authorization',
      'Bearer ' + this.oauthService.getAccessToken()
    );
    this.clearCookiesAndLogout(headers);
  }

  private handleCustomerSelectionChange(customerId: string) {
    this.adminLoginService.setCustomerId(customerId);
    this.adminLoginService.setCustomerCookie$()
      .subscribe(() => {
        this.adminLoginService.emitCustomerSelectionChanged(true);
      });
  }

  private clearCookiesAndLogout(headers: HttpHeaders) {
    this.logoutService.invalidateCookie$(headers)
      .pipe(
        switchMap(() => this.logoutService.clearCustomerCookie$()),
        catchError((error) => {
          this.authService.logOut();
          return of(null);
        })
      )
      .subscribe(
        () => {
          this.authService.logOut();
        },
        (error) => {
          this.authService.logOut();
        }
      );
  }

}
