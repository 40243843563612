import {CustomToastComponent} from './shared/custom-toast/custom-toast.component';
import {ToastrModule, ToastContainerModule} from 'ngx-toastr';
import {DriverService} from './../services/driver.service';
import {UserService} from './../services/user.service';
import {CustomerService} from './../services/customer.service';
import {DocMgmtService} from '../services/doc-mgmt.service';
import {TerminalService} from '../services/terminal.service';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentDashboardComponent} from './document-dashboard/document-dashboard.component';
import {UnitComponent} from './document-dashboard/unit/unit.component';
import {FileComponent} from './document-dashboard/file/file.component';
import {GroupComponent} from './document-dashboard/group/group.component';
import {UploadComponent} from './document-dashboard/upload/upload.component';
import {SharedModule} from './shared/shared.module';
import {FileDetailComponent} from './document-dashboard/file-detail/file-detail.component';
import {UnitDetailComponent} from './document-dashboard/unit-detail/unit-detail.component';
import {GroupDetailComponent} from './document-dashboard/group-detail/group-detail.component';
import {IncomingDocumentsComponent} from './document-dashboard/incoming-documents/incoming-documents.component';
import {TidLoginComponent} from '../tid-login/tid-login.component';
import {ErrorComponent} from '../error/error.component';
import {AioHeaderComponent} from '../aio-header/aio-header.component';
import {AioSideNavComponent} from '../aio-side-nav/aio-side-nav.component';

@NgModule({
  declarations: [
    DocumentDashboardComponent,
    UnitComponent,
    FileComponent,
    GroupComponent,
    UploadComponent,
    FileDetailComponent,
    UnitDetailComponent,
    GroupDetailComponent,
    CustomToastComponent,
    IncomingDocumentsComponent,
    TidLoginComponent,
    ErrorComponent,
    AioHeaderComponent,
    AioSideNavComponent,
  ],
  exports: [DocumentDashboardComponent],
  imports: [
    CommonModule,
    SharedModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      timeOut: 3000,
      preventDuplicates: true,
      toastComponent: CustomToastComponent, // custom toast
    }),
    ToastContainerModule,
  ],
  providers: [DocMgmtService, TerminalService, UserService, DriverService, CustomerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DocumentModule {
}
