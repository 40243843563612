export enum DeviceType {
    "Unknown",
    "CarCube 2",
    "Kepler",
    "CarBox",
    "SC 200",
    "PC Client",
    "CarCube 3",
    "Android",
    "CarCube 4",
    "CarCube 3T4U",
    "CarCube 3W",
    "CarCube 5",
    "CarBox 3",
    "CarCube 6",
    "FXPS Tablet",
    "Trailer4U"
}
