import { CommonConfig } from "./../config/common-config";
import { CustomTranslateService } from "../../../services/custom-translate.service";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  SimpleChanges,
  SimpleChange,
  OnChanges,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatMenuTrigger } from "@angular/material/menu";
import { TableConfig } from "./table-config/table-config";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { DocMgmtService } from "src/app/services/doc-mgmt.service";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";

@Component({
  selector: "dms-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  /** Input from parent to config the table with necessary functionality */
  @Input() tableConfig: TableConfig;
  /** Input from parent actual table data */
  @Input() tableData: any[];

  @Input() commonConfig: CommonConfig;

  @Input() isWebView: boolean = true;

  @Output() selectedRow = new EventEmitter();

  @Output() currentPageData = new EventEmitter();

  @Output() action = new EventEmitter();

  dataSource = new MatTableDataSource([]);
  selection: SelectionModel<any>;
  contextMenuPosition = { x: "0px", y: "0px" };
  selectedRowId: any;
  actionInProgress: boolean;
  isLoading = false;
  toggleLoading = () => (this.isLoading = !this.isLoading);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild(InfiniteScrollDirective)
  infiniteScrollDirective: InfiniteScrollDirective;
  @ViewChild("tableComponent") table: ElementRef;

  constructor(
    private readonly translateService: CustomTranslateService,
    private readonly docMgmtService: DocMgmtService,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const tableData: SimpleChange = changes.tableData;
    if (tableData && tableData.currentValue) {
      this.dataSource.data = tableData.currentValue;
      this.dataSource._updateChangeSubscription();
      if (this.commonConfig && this.commonConfig.pageNumber === 1) {
        this.resetInfiniteScroll();
      }
    }
    if (changes.isWebView) {
      this.isWebView = changes.isWebView.currentValue;
    }
  }

  resetInfiniteScroll() {
    this.infiniteScrollDirective.ngOnDestroy();
    this.infiniteScrollDirective.setup();
    this.table.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  ngOnInit(): void {
    this.createCommonConfig();
    /** create default object if table config is not specified */
    if (!this.tableConfig) {
      this.tableConfig = new TableConfig();
    }
    /** checkbox column */
    if (this.tableConfig.showCheckbox) {
      const index = this.tableConfig.displayedColumnNames.indexOf(
        "commonCheckBoxSelect"
      );
      if (index < 0) {
        this.tableConfig.displayedColumnNames.unshift("commonCheckBoxSelect");
      }
    }
    if (this.tableConfig.actionMenuList.length > 0) {
      const index =
        this.tableConfig.displayedColumnNames.indexOf("actionColumn");
      if (index < 0) {
        this.tableConfig.displayedColumnNames.push("actionColumn");
      }
    }
    this.dataSource.sort = this.sort;
    this.selection = new SelectionModel<any>(
      this.tableConfig.multipleSelection,
      []
    );
    this.dataSource.filterPredicate = this.createFilter();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "createdDate": {
          if (item.createdDate) {
            const [day, month, year] = item.createdDate.split("/");
            const formattedDate = month + "/" + day + "/" + year;
            return new Date(formattedDate);
          }
          break;
        }
        case "formattedFileSize":
          return item["fileSize"];
        default:
          return item[property];
      }
    };
  }
  createFilter() {
    const filterFunction = (data: any, filter: string) => {
      let matchFound = false;
      for (const column of this.tableConfig.displayedColumnNames) {
        if (column in data) {
          if (data[column]) {
            matchFound =
              matchFound ||
              data[column]
                .toString()
                .trim()
                .toLowerCase()
                .indexOf(filter.trim().toLowerCase()) !== -1;
          }
        }
      }
      return matchFound;
    };
    return filterFunction;
  }

  ngAfterViewInit() {
    if (this.tableConfig.pagination && this.paginator && this.dataSource) {
      this.paginator._intl.itemsPerPageLabel =
        this.translateService.getTranslatedValue("itemsPerPage");
      this.paginator._intl.nextPageLabel = "";
      this.paginator._intl.previousPageLabel = "";
      this.paginator._intl.getRangeLabel = (
        page: number,
        pageSize: number,
        length: number
      ) => {
        if (length === 0 || pageSize === 0) {
          return `0 ${this.translateService.getTranslatedValue(
            "of"
          )} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
          startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
        return `${
          startIndex + 1
        } - ${endIndex} ${this.translateService.getTranslatedValue(
          "of"
        )} ${length}`;
      };
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.pageDataChange();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    let disabledCheckboxLength = 0;
    const numSelected = this.findNumOfRowsSelected();
    const numRows = this.dataSource.filteredData.length;
    this.dataSource.data.forEach((data) => {
      if (data.isCheckboxDisabled) {
        disabledCheckboxLength = disabledCheckboxLength + 1;
      }
    });
    return numSelected === numRows - disabledCheckboxLength;
  }

  findNumOfRowsSelected(): number {
    let numOfRowsSelected = 0;
    this.selection.selected.forEach((selectedRow) => {
      const isRowVisible = this.dataSource.filteredData.includes(selectedRow);
      if (isRowVisible) {
        numOfRowsSelected++;
      }
    });
    return numOfRowsSelected;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(event : CustomEvent) {
    if (event.detail) {
      if (!this.isAllSelected()) {
        this.dataSource.filteredData.forEach((row) => {
          if (!row?.isCheckboxDisabled) {
            this.selection.select(row);
          }
        });
      }
    } else {
      this.dataSource.filteredData.length === this.dataSource.data.length
        ? this.selection.clear()
        : this.dataSource.filteredData.forEach((row) => {
            if (!row?.isCheckboxDisabled) {
              this.selection.deselect(row);
            }
          });
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  onContextMenu(event, item) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  onContextMenuAction(item, menu) {}

  /** get selected rows of table */
  getSelection() {
    return this.selection.selected;
  }

  rowOnClick(row) {
    if (!(this.selection.selected[0] === row)) {
      this.selection.clear();
      this.selection.select(row);
      this.selectedRow.emit(row);
    }
  }

  checkBoxEvent(event, row, selection) {
    event ? selection.toggle(row) : null;
  }
  checkBoxHeaderEvent(event) {
    if(event) this.masterToggle(event);
  }

  clearSelection() {
    this.selection.clear();
  }

  pageDataChange() {
    if (this.paginator) {
      const dataToSkip = this.paginator.pageSize * this.paginator.pageIndex;
      this.currentPageData.emit(
        this.dataSource
          .sortData(this.dataSource.filteredData, this.dataSource.sort)
          .filter((u, i) => i >= dataToSkip)
          .filter((u, i) => i < this.paginator.pageSize)
      );
    }
  }

  doAction(row, actionItem) {
    if ("Delete" === actionItem) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "400px",
        height: "200px",
        data: this.translateService.getTranslatedValue("confirmDelete"),
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.action.emit({
            type: actionItem,
            row: row,
          });
        }
      });
    } else {
      this.selectedRowId = row;
      this.actionInProgress = true;
      this.action.emit({
        type: actionItem,
        row: row,
      });
    }
  }

  onActionComplete() {
    this.selectedRowId = null;
    this.actionInProgress = false;
  }

  onScroll(event) {
    if (this.tableConfig.infiniteScrollEnabled) {
      if (!this.docMgmtService.lastPage) {
        this.commonConfig.pageNumber++;
        this.appendData();
      }
    }
  }
  appendData(): void {
    this.toggleLoading();
    this.docMgmtService.getFileMetadata({
      page: this.commonConfig.pageNumber,
      filterValue: this.commonConfig.searchFilterValue,
      startDate: this.commonConfig.startDate?.getTime(),
      endDate: this.commonConfig.endDate?.getTime(),
    });
  }

  createCommonConfig() {
    if (!this.commonConfig) {
      this.commonConfig = new CommonConfig();
    }
  }
}
