import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {CloudUserProfile, TidUserProfile} from '../models/user-profile.model';
import {DMSConfigService} from './dmsconfig.service';
import {HandleErrorService} from './handle-error.service';
import {UserService} from './user.service';
import {CustomTranslateService} from './custom-translate.service';
import {tap} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class LoginService {
  authConfig: AuthConfig;
  userNavigatedUrl = '/dms';

  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dmsConfigService: DMSConfigService,
    private readonly handleErrorService: HandleErrorService,
    private readonly translateService: CustomTranslateService
  ) {
    const config = this.dmsConfigService.getConfig();
    this.authConfig = {
      clientId: config.CLIENT_ID,
      issuer: config.ISSUER,
      scope: config.SCOPE,
      responseType: 'code',
      redirectUri: config.REDIRECT_URI,
      logoutUrl: config.LOGOUT_URL,
      userinfoEndpoint: config.USER_INFO_ENDPOINT,
    };
  }

  isSessionValid() {
    return (
      this.oauthService.getAccessToken() != null &&
      this.oauthService.hasValidAccessToken() &&
      this.userService.tidUserProfile.getValue() != null
    );
  }

  setNavigatedUrl(url: string) {
    this.userNavigatedUrl = url;
  }

  login() {
    this.handleErrorService.errorMessage = '';
    if (this.isSessionValid()) {
      this.router.navigate([this.userNavigatedUrl]);
      return;
    }
    this.oauthService.configure(this.authConfig);
    this.oauthService.loadDiscoveryDocument().then(
      () => {
        this.oauthService.tryLoginCodeFlow().then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService.initLoginFlow();
          } else {
            this.userService.getCloudUserProfile().pipe(
              tap(cloudUserProfile => this.translateService.setLanguageTranslation(cloudUserProfile.language)),
              tap(cloudUserProfile => localStorage.setItem('userLanguage', cloudUserProfile.language)),
              map(cloudUserProfile => this.createTidUserProfile(cloudUserProfile)),
              tap(tidUserProfile => this.userService.tidUserProfile.next(tidUserProfile))
            ).subscribe(() => {
              this.router.navigate([this.userNavigatedUrl]);
            });
          }
        });
      },
      (reason) =>
        console.error(
          'Could not load authentication document',
          'Warning',
          reason
        )
    );
  }

  private readonly createTidUserProfile = (cloudUserProfile: CloudUserProfile): TidUserProfile => {
    const tidUserProfile = new TidUserProfile();
    tidUserProfile.tidEmail = cloudUserProfile.email;
    tidUserProfile.givenName = cloudUserProfile.firstName;
    tidUserProfile.familyName = cloudUserProfile.lastName;
    tidUserProfile.tidFullName = `${cloudUserProfile.lastName} ${cloudUserProfile.firstName}`;
    tidUserProfile.uuid = cloudUserProfile.uuid;
    tidUserProfile.language = cloudUserProfile.language;
    tidUserProfile.timeZone = cloudUserProfile.timezone;
    return tidUserProfile;
  };
}
