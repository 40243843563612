export class Link {
  id: number;
  document: Document;
  owner: Owner;
}

export class Document {
  id: string;
  name: string;
  ext: string;
}

export class Owner {
  customerNr: string | null;
  id: string;
  type: Type;
}

export enum Type {
  CUSTOMER = 'CUSTOMER',
  GROUP = 'GROUP',
  UNIT = 'UNIT'
}
