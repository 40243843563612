import { FormGroup, ValidatorFn } from "@angular/forms";
import { CommonConfig } from "./config/common-config";

export interface DateRangeValidation {
  startDateNotSelected?: boolean;
  startDateGreaterThanEndDate?: boolean;
  timeExceed?: boolean;
  startDate?: Date;
  endDate?: Date;
}

export const START_DATE_KEY = "startDate";
export const END_DATE_KEY = "endDate";
export const MS_PER_DAY = 86_400_000;
export const NUM_DAYS_THRESHOLD = 31;

export class DateRangeValidator {
  static readonly dateRangeValidator = (commonConfig: CommonConfig): ValidatorFn => {
    return (formGroup: FormGroup): DateRangeValidation | null => {
      const startDateForm = formGroup.get(START_DATE_KEY);
      const endDateForm = formGroup.get(END_DATE_KEY);

      const startDateValue = startDateForm?.value;
      const endDateValue = endDateForm?.value;

      if (!startDateValue && !endDateValue) {
        return this.initializeConfig(commonConfig);
      } else if (!startDateValue && endDateValue) {
        return this.buildStartNotSelectedError();
      } else if (startDateValue && !endDateValue) {
        return this.handleStartDateSelected(
          startDateValue,
          commonConfig
        );
      } else {
        return this.validateDateRange(
          startDateValue,
          endDateValue,
          commonConfig
        );
      }
    };
  };

  private static readonly initializeConfig = (commonConfig: CommonConfig): null => {
    commonConfig.startDate = this.getTruncatedHoursDate(new Date());
    commonConfig.endDate = this.incrementDays(
      commonConfig.startDate,
      1
    );
    return null;
  };

  private static readonly handleStartDateSelected = (
    startDateValue: Date,
    commonConfig: CommonConfig
  ): null => {
    const startDate = this.getTruncatedHoursDate(
      new Date(startDateValue)
    );
    commonConfig.startDate = startDate;
    commonConfig.endDate = this.incrementDays(startDate, 1);
    return null;
  };

  private static readonly validateDateRange = (
    startDateValue: Date,
    endDateValue: Date,
    commonConfig: CommonConfig
  ): DateRangeValidation | null => {
    const startDate = this.getTruncatedHoursDate(
      new Date(startDateValue)
    );
    const endDate = this.getTruncatedHoursDate(
      new Date(endDateValue)
    );

    if (endDate.getTime() < startDate.getTime()) {
      return this.buildStartDateGreaterThanEndDateError();
    }

    if (
      this.isDaysDifferenceOverflowed(
        startDate,
        endDate,
        NUM_DAYS_THRESHOLD
      )
    ) {
      return this.buildTimeExceedError();
    }

    commonConfig.startDate = startDate;
    commonConfig.endDate = this.incrementDays(endDate, 1);

    return null;
  };

  private static readonly getTruncatedHoursDate = (date: Date): Date => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  private static readonly incrementDays = (date: Date, days: number): Date => {
    const incrementedDate = new Date(date.getTime());
    incrementedDate.setDate(date.getDate() + days);
    return incrementedDate;
  };

  private static readonly isDaysDifferenceOverflowed = (
    startDate: Date,
    endDate: Date,
    threshold: number
  ): boolean => {
    return this.getDaysDifference(startDate, endDate) > threshold;
  };

  private static readonly getDaysDifference = (startDate: Date, endDate: Date): number => {
    return Math.round(
      Math.abs(Number(endDate) - Number(startDate)) / MS_PER_DAY
    );
  };

  private static readonly buildStartNotSelectedError = (): DateRangeValidation => {
    return { startDateNotSelected: true };
  };

  private static readonly buildStartDateGreaterThanEndDateError = (): DateRangeValidation => {
    return { startDateGreaterThanEndDate: true };
  };

  private static readonly buildTimeExceedError = (): DateRangeValidation => {
    return { timeExceed: true };
  };
}
