import {Document, Link, Owner} from '../../../models/link.model';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {RightPanelService} from '../../../services/right-panel.service';
import {FileMetadata} from '../../../models/file-metadata.model';
import {ConfigurationGroup} from '../../../models/configuration-group.model';
import {Subscription} from 'rxjs';
import {TableConfig} from '../../shared/table/table-config/table-config';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Utils from 'src/app/utils/utils';
import {Type} from 'src/app/models/link.model';
import {TableComponent} from '../../shared/table/table.component';
import {HandleErrorService} from '../../../services/handle-error.service';
import {CustomTranslateService} from '../../../services/custom-translate.service';

@Component({
  selector: 'dms-group-detail',
  templateUrl: './group-detail.component.html'
})
export class GroupDetailComponent implements OnInit, OnDestroy {

  @ViewChild('assignedTable') assignedTable: TableComponent;
  @ViewChild('unassignedTable') unassignedTable: TableComponent;

  assignedTableConfig = new TableConfig();
  unassignedTableConfig = new TableConfig();
  assignedTableData: any[] = null;
  unassignedTableData: any[] = null;
  subscriptions: Subscription[] = [];
  groupData: ConfigurationGroup;
  fileMetadata: FileMetadata[];

  constructor(
    private readonly rightPanelService: RightPanelService,
    private readonly docMgmtService: DocMgmtService,
    private readonly handleErrorService: HandleErrorService,
    private readonly translateService: CustomTranslateService) {
  }

  ngOnInit(): void {
    /** assigned table */
    this.assignedTableConfig.contentHeight = 70;
    this.assignedTableConfig.headerCheckbox = true;
    this.assignedTableConfig.iconColumnName = 'fileName';
    this.assignedTableConfig.displayedColumnNames = ['fileName', 'createdDate', 'formattedFileSize', 'assignedAt'];
    this.assignedTableConfig.multipleSelection = true;
    this.assignedTableConfig.showCustomSearchField = true;
    /**unassigned table */
    this.unassignedTableConfig.contentHeight = 70;
    this.unassignedTableConfig.headerCheckbox = true;
    this.unassignedTableConfig.iconColumnName = 'fileName';
    this.unassignedTableConfig.displayedColumnNames = ['fileName', 'createdDate', 'formattedFileSize'];
    this.unassignedTableConfig.multipleSelection = true;
    this.unassignedTableConfig.showCustomSearchField = true;

    this.subscriptions.push(this.docMgmtService.fullFileMetadata.subscribe((value) => {
      if (value) {
        this.fileMetadata = value;
      }
    }));

    this.subscriptions.push(this.rightPanelService.selectedGroup.subscribe((value: ConfigurationGroup) => {
      if (value && Object.keys(value).length !== 0) {
        this.loadRightPanel(value);
      } else {
        this.groupData = null;
      }
    }));
  }

  async loadRightPanel(value) {
    await this.rightPanelService.openPanel();
    this.tableClearSelection();
    this.groupData = value;
    this.assignedTableData = null;
    this.unassignedTableData = null;
    this.loadTableData(value.id);
  }

  loadTableData(groupId) {
    let error = false;
    let linkDetails = [];
    this.docMgmtService.getAssignDetails(null, groupId, Type.GROUP).subscribe((groupLinks: Link[]) => {
      this.docMgmtService.getAssignDetails(null, null, Type.CUSTOMER).subscribe((customerLinks: Link[]) => {
        linkDetails = groupLinks.concat(customerLinks);
        this.assignedTableData = Utils.getAssignedFileDetails(this.fileMetadata, linkDetails, 'grouppanel');
        this.unassignedTableData = Utils.getUnassignedFileDetails(this.fileMetadata, linkDetails);
      }, (customerError) => {
        error = true;
      });
    }, (terminalError) => {
      error = true;
    });
    if (error) {
      this.assignedTableData = [];
      this.unassignedTableData = [];
    }
  }

  async unassignDocument() {
    if (this.assignedTable && this.assignedTable.getSelection()?.length > 0) {
      for (let i = 0; i < this.assignedTable.getSelection().length; i++) {
        await this.docMgmtService.unAssignDocument(this.assignedTable.getSelection()[i].linkId);
      }
      this.loadRightPanel(this.groupData);
    } else {
      this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('selectDocumentsUnassign'), '');
    }
  }

  async assignDocument() {
    if (this.unassignedTable && this.unassignedTable.getSelection()?.length > 0) {
      for (let row = 0; row < this.unassignedTable.getSelection().length; row++) {
        const selectedDoc: FileMetadata = this.unassignedTable.getSelection()[row];
        const link = new Link();
        link.document = new Document();
        link.document.id = selectedDoc.fileKey;
        link.document.name = selectedDoc.fileName;
        link.document.ext = selectedDoc.fileType;
        const owner: Owner = new Owner();
        owner.customerNr = null;
        owner.id = this.groupData.id;
        owner.type = Type.GROUP;
        link.owner = owner;
        await this.docMgmtService.assignDocument(link);
      }
      this.loadRightPanel(this.groupData);
    } else {
      this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('selectDocumentsAssign'), '');
    }
  }

  tableClearSelection() {
    this.assignedTable?.clearSelection();
    this.unassignedTable?.clearSelection();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
