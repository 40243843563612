import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {DMSConfigService} from './dmsconfig.service';
import {Customer} from '../models/customer.model';

@Injectable()
export class CustomerService {
  customer = new BehaviorSubject<Customer>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly dmsConfigService: DMSConfigService) {
  }

  getCustomerDetail$(): Observable<Customer> {
    return this.http.get<Customer>(`${this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL}/customers`);
  }
}
