import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CloudUserProfile, TidUserProfile} from '../models/user-profile.model';
import {BehaviorSubject} from 'rxjs';
import {DMSConfigService} from './dmsconfig.service';
import {OAuthService} from 'angular-oauth2-oidc';
import * as timeZones from '../../assets/timeZones.json';

@Injectable()
export class UserService {
  tidUserProfile = new BehaviorSubject<TidUserProfile>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly dmsConfigService: DMSConfigService,
    private readonly oauthService: OAuthService
  ) {
  }

  getCloudUserProfile() {
    const accessToken = this.oauthService.getAccessToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    const userProfileUrl = this.dmsConfigService.getConfig().CLOUD_USER_PROFILE_URL;

    return this.http.get<CloudUserProfile>(userProfileUrl, {headers});
  }

  getLocale(): string {
    return this.getTidUserProfile()?.language ?? 'en-GB';
  }

  getTimezone(): string {
    const timeZoneCode = this.getTidUserProfile()?.timeZone;
    return this.interpretTimeZone(timeZoneCode) ?? 'CET';
  }

  private interpretTimeZone(timeZoneCode: string): string {
    return timeZones[timeZoneCode] || null;
  }

  getTidUserProfile() {
    return this.tidUserProfile.getValue();
  }
}
