import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AppComponent } from "./app.component";
import { APP_BASE_HREF } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DocumentModule } from "./document/document.module";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { DocMgmtInterceptor } from "./config/doc-mgmt.interceptor";
import { DMSConfigService } from "./services/dmsconfig.service";
import { APP_INITIALIZER } from "@angular/core";
import { OAuthModule, OAuthService } from "angular-oauth2-oidc";
import { AppRoutingModule } from "./app-routing.module";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { LoginService } from "./services/login.service";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

const dmsInitializerFn = (dmsConfig: DMSConfigService) => {
  return () => {
    return dmsConfig.loadDMSConfig();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    DocumentModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    OAuthService,
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: dmsInitializerFn,
      multi: true,
      deps: [DMSConfigService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: DocMgmtInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: "/" },
    LoginService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
