import {Component} from '@angular/core';
import {datadogRum} from '@datadog/browser-rum';
import {DMSConfigService} from './services/dmsconfig.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private readonly dmsConfigService: DMSConfigService) {
  }

  ngOnInit() {
    const config = this.dmsConfigService.getConfig();
    datadogRum.init({
      env: config.DD_ENV,
      applicationId: config.DD_APPID,
      clientToken: config.DD_CLIENT_TOKEN,
      site: config.DD_SITE,
      service: config.DD_SERVICE,
      traceSampleRate: config.DD_TRACE_SAMPLE_RATE,
      trackUserInteractions: true,
      version: config.DD_VERSION
    });
  }
}
