import Utils from 'src/app/utils/utils';
import {Document, Link, Owner, Type} from '../../../models/link.model';
import {FileMetadata} from '../../../models/file-metadata.model';
import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {RightPanelService} from '../../../services/right-panel.service';
import {Terminal} from '../../../models/terminal.model';
import {Subscription} from 'rxjs';
import {TableConfig} from '../../shared/table/table-config/table-config';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TableComponent} from '../../shared/table/table.component';
import {HandleErrorService} from '../../../services/handle-error.service';
import {CustomTranslateService} from '../../../services/custom-translate.service';

@Component({
  selector: 'dms-unit-detail',
  templateUrl: './unit-detail.component.html'
})
export class UnitDetailComponent implements OnInit, OnDestroy {

  @ViewChild('assignedTable') assignedTable: TableComponent;
  @ViewChild('unassignedTable') unassignedTable: TableComponent;

  assignedTableConfig = new TableConfig();
  unassignedTableConfig = new TableConfig();
  assignedTableData: any[] = null;
  unassignedTableData: any[] = null;
  subscriptions: Subscription[] = [];
  terminalData: Terminal;
  fileMetadata: FileMetadata[];

  constructor(private readonly rightPanelService: RightPanelService,
              private readonly docMgmtService: DocMgmtService,
              private readonly handleErrorService: HandleErrorService,
              private readonly translateService: CustomTranslateService) {
  }

  ngOnInit(): void {
    /** assigned table */
    this.assignedTableConfig.contentHeight = 70;
    this.assignedTableConfig.headerCheckbox = true;
    this.assignedTableConfig.iconColumnName = 'fileName';
    this.assignedTableConfig.displayedColumnNames = ['fileName', 'createdDate', 'formattedFileSize', 'assignedAt'];
    this.assignedTableConfig.multipleSelection = true;
    this.assignedTableConfig.showCustomSearchField = true;
    /**unassigned table */
    this.unassignedTableConfig.contentHeight = 70;
    this.unassignedTableConfig.headerCheckbox = true;
    this.unassignedTableConfig.iconColumnName = 'fileName';
    this.unassignedTableConfig.displayedColumnNames = ['fileName', 'createdDate', 'formattedFileSize'];
    this.unassignedTableConfig.multipleSelection = true;
    this.unassignedTableConfig.showCustomSearchField = true;

    this.subscriptions.push(this.docMgmtService.fullFileMetadata.subscribe((value) => {
      if (value) {
        this.fileMetadata = value;
      }
    }));

    this.subscriptions.push(this.rightPanelService.selectedUnit.subscribe((value: Terminal) => {
      if (value && Object.keys(value).length !== 0) {
        this.loadRightPanel(value);
      } else {
        this.terminalData = null;
      }
    }));
  }

  async loadRightPanel(value) {
    await this.rightPanelService.openPanel();
    this.tableClearSelection();
    this.terminalData = value;
    this.assignedTableData = null;
    this.unassignedTableData = null;
    this.loadTableData(value);
  }

  loadTableData(terminalDetails: Terminal) {
    let error = false;
    let linkDetails = [];
    this.docMgmtService.getAssignDetails(null, terminalDetails?.terminalId, Type.UNIT).subscribe((terminalLinks: Link[]) => {
      this.docMgmtService.getAssignDetails(null, terminalDetails?.configurationGroup?.id, Type.GROUP).subscribe((groupLinks: Link[]) => {
        this.docMgmtService.getAssignDetails(null, null, Type.CUSTOMER).subscribe((customerLinks: Link[]) => {
          linkDetails = terminalLinks.concat(groupLinks).concat(customerLinks);
          this.assignedTableData = Utils.getAssignedFileDetails(this.fileMetadata, linkDetails, 'unitpanel');
          this.unassignedTableData = Utils.getUnassignedFileDetails(this.fileMetadata, linkDetails);
        }, (customerError) => {
          error = true;
        });
      }, (groupError) => {
        error = true;
      });
    }, (terminalError) => {
      error = true;
    });
    if (error) {
      this.assignedTableData = [];
      this.unassignedTableData = [];
    }
  }

  async unassignDocument() {
    if (this.assignedTable && this.assignedTable.getSelection()?.length > 0) {
      for (let i = 0; i < this.assignedTable.getSelection().length; i++) {
        await this.docMgmtService.unAssignDocument(this.assignedTable.getSelection()[i].linkId);
      }
      this.loadRightPanel(this.terminalData);
    } else {
      this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('selectDocumentsUnassign'), '');
    }
  }

  async assignDocument() {
    if (this.unassignedTable && this.unassignedTable.getSelection()?.length > 0) {
      for (let row = 0; row < this.unassignedTable.getSelection().length; row++) {
        const selectedDoc: FileMetadata = this.unassignedTable.getSelection()[row];
        const link = new Link();
        link.document = new Document();
        link.document.id = selectedDoc.fileKey;
        link.document.name = selectedDoc.fileName;
        link.document.ext = selectedDoc.fileType;
        const owner: Owner = new Owner();
        owner.customerNr = null;
        owner.id = this.terminalData.terminalId;
        owner.type = Type.UNIT;
        link.owner = owner;
        await this.docMgmtService.assignDocument(link);
      }
      this.loadRightPanel(this.terminalData);
    } else {
      this.handleErrorService.displayErrorDialog(this.translateService.getTranslatedValue('selectDocumentsAssign'), '');
    }
  }

  tableClearSelection() {
    this.assignedTable?.clearSelection();
    this.unassignedTable?.clearSelection();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
