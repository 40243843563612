import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HandleErrorService} from '../services/handle-error.service';
import {Subject, takeUntil} from 'rxjs';
import {AdminLoginService} from '../services/admin-login.service';

@Component({
  selector: 'dms-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  constructor(
    readonly handleErrorService: HandleErrorService,
    private readonly router: Router,
    private readonly adminLoginService: AdminLoginService
  ) {
  }

  private readonly stop$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.adminLoginService.customerSelectionChanged$
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        if (this.router.url === '/error') {
          this.router.navigate(['/login']);
        }
      });
    if (!this.handleErrorService.errorMessage) {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

}
