import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dms-upload',
  templateUrl: './upload.component.html'
})
export class UploadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
