import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

@Injectable()
export class RightPanelService {

  private rightPanel: MatSidenav;
  selectedFile = new BehaviorSubject<any>(null);
  selectedUnit = new BehaviorSubject<any>(null);
  selectedGroup = new BehaviorSubject<any>(null);


  setRightPanelInstance(rightPanel: MatSidenav) {
    this.rightPanel = rightPanel;
  }

  openPanel() {
    if (this.rightPanel && !this.rightPanel.opened) {
      this.rightPanel?.open();
    }
  }

  closePanel() {
    this.rightPanel.close();
  }
}
