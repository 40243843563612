import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {Component, OnInit} from '@angular/core';

import {Toast, ToastrService, ToastPackage} from 'ngx-toastr';
import {AdminLoginService} from '../../../services/admin-login.service';

@Component({
  selector: '[custom-toast-component]',
  styleUrls: [`./custom-toast.component.css`],
  templateUrl: `./custom-toast.component.html`,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 1,
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class CustomToastComponent extends Toast implements OnInit {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private readonly adminLoginService: AdminLoginService
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    if (this.message === 'Customer not selected') {
      this.adminLoginService.emitCustomerSelectionChanged(false);
      this.toastrService.remove(this.toastPackage.toastId);
    }
  }

}
