import { Component, OnInit } from "@angular/core";
import { LoginService } from "../services/login.service";

@Component({
  selector: "dms-tid-login",
  templateUrl: "./tid-login.component.html",
  styleUrls: ["./tid-login.component.scss"],
})
export class TidLoginComponent implements OnInit {
  constructor(private readonly loginService: LoginService) {}

  ngOnInit(): void {
    this.loginService.login();
  }
}
