import {HandleErrorService} from '../services/handle-error.service';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class DocMgmtInterceptor implements HttpInterceptor {
  constructor(private readonly handleErrorService: HandleErrorService, private readonly oauthService: OAuthService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/fleet') > 0) {
      request = request.clone({
        withCredentials: true,
      });
    }
    if (request.url.includes('/api/gateway-dms/')) {
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          'Authorization': `Bearer ${this.oauthService.getAccessToken()}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleErrorService.handleError(error);
        // Error message can also be notified
        return throwError(error);
      })
    );
  }
}
