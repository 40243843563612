import {ConfigurationGroup} from '../models/configuration-group.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Terminal} from '../models/terminal.model';
import {BehaviorSubject} from 'rxjs';
import Utils from '../utils/utils';
import {DMSConfigService} from './dmsconfig.service';

@Injectable()
export class TerminalService {
  terminals = new BehaviorSubject<Terminal[]>(null);
  configurationGroups = new BehaviorSubject<ConfigurationGroup[]>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly dmsConfigService: DMSConfigService) {
  }

  getTerminals() {
    this.http
      .get(
        `${this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL}/terminals`
      )
      .subscribe(
        (terminals: Terminal[]) => {
          const formattedTerminals: Terminal[] =
            Utils.formatTerminals(terminals);
          this.terminals.next(formattedTerminals);
          const configGroups: ConfigurationGroup[] =
            Utils.getUniqueConfigGroups(formattedTerminals);
          this.configurationGroups.next(configGroups);
        },
        (error) => {
          this.terminals.next([]);
          this.configurationGroups.next([]);
        }
      );
  }
}
