import { RightPanelService } from './../../../services/right-panel.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TerminalService } from '../../../services/terminal.service';
import { TableConfig } from '../../shared/table/table-config/table-config';
import { Subscription } from 'rxjs';
import { TableComponent } from '../../shared/table/table.component';

@Component({
  selector: 'dms-group',
  templateUrl: './group.component.html'
})
export class GroupComponent implements OnInit, OnDestroy {

  @ViewChild('groupTable') groupTable: TableComponent;

  tableConfig: TableConfig;
  tableData: any[] = null;
  subscription: Subscription;

  constructor(private terminalService: TerminalService, private rightPanelService: RightPanelService) { }

  ngOnInit(): void {
    this.tableConfig = new TableConfig();
    this.tableConfig.showCheckbox = false;
    this.tableConfig.rowClickAction = true;
    this.tableConfig.displayedColumnNames = ['groupName', 'unitCount'];
    this.subscription = this.terminalService.configurationGroups.subscribe(groups => {
      this.tableData = groups;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  rowSelected(row) {
    if (row) {
      this.rightPanelService.selectedGroup.next(row);
    }
  }

  clearTableSelection() {
    if (this.groupTable) {
      this.groupTable.clearSelection();
    }
  }

}
