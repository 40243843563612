import { HandleErrorService } from "./../../services/handle-error.service";
import { TranslatePipe } from "./../../pipe/translate.pipe";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "./table/table.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faFileAlt,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faSearch,
  faTimes,
  faUnlink,
  faLink,
  faCheckCircle,
  faSortDown,
  faSortUp,
  faExclamationTriangle,
  faEye,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RightPanelService } from "./../../services/right-panel.service";
import { faTimesCircle, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { MatDialogModule } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { DateFilterComponent } from "./date-filter/date-filter.component";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    TableComponent,
    TranslatePipe,
    ConfirmationDialogComponent,
    DateFilterComponent,
  ],
  exports: [
    TableComponent,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    FontAwesomeModule,
    MatSidenavModule,
    TranslatePipe,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    DateFilterComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    MatSidenavModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
  ],
  entryComponents: [ConfirmationDialogComponent],
  providers: [RightPanelService, HandleErrorService],
})
export class SharedModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faFileAlt,
      faFilePdf,
      faFileImage,
      faFileVideo,
      faTimesCircle,
      faSearch,
      faTimes,
      faUnlink,
      faLink,
      faCheckCircle,
      faSortDown,
      faSortUp,
      faExclamationTriangle,
      faTrashAlt,
      faEye,
      faDownload
    );
  }
}
