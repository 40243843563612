import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { DocumentDashboardComponent } from "./document/document-dashboard/document-dashboard.component";
import { ErrorComponent } from "./error/error.component";
import { TidLoginComponent } from "./tid-login/tid-login.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: TidLoginComponent,
  },
  {
    path: "dms",
    component: DocumentDashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "error", component: ErrorComponent },
  { path: "**", redirectTo: "login" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
