<div class="main-container">
  <div class="header-container">
    <dms-aio-header></dms-aio-header>
  </div>
  <div class="body-container">
    <div class="side-nav-container">
      <dms-aio-side-nav></dms-aio-side-nav>
    </div>
    <div class="module-container">
      <div class="mainError">
        <div class="mainErrorText">
          <span *ngIf="handleErrorService.errorMessage" class="error-message">
            {{ handleErrorService.errorMessage | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
