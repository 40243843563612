import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DMSConfigService} from '../services/dmsconfig.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private readonly http: HttpClient,
    private readonly dmsConfigService: DMSConfigService) {
  }

  invalidateCookie$(headers: HttpHeaders): Observable<void> {
    return this.http.get<void>(
      this.dmsConfigService.getConfig().API_AUTHENTICATION_URL + '/cookie/invalidate',
      {headers: headers}
    );
  }

  clearCustomerCookie$(): Observable<void> {
    return this.http.delete<void>(this.dmsConfigService.getConfig().API_GATEWAY_AUTHENTICATION_URL);
  }
}
