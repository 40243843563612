import {DocMgmtService} from '../../../services/doc-mgmt.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  TableConfig,
} from '../../shared/table/table-config/table-config';
import {EMPTY, Subject, switchMap, takeUntil} from 'rxjs';
import {TableComponent} from '../../shared/table/table.component';
import {RightPanelService} from '../../../services/right-panel.service';
import {Link} from 'src/app/models/link.model';
import {HandleErrorService} from 'src/app/services/handle-error.service';
import {CustomTranslateService} from 'src/app/services/custom-translate.service';
import {TerminalService} from '../../../services/terminal.service';
import {ConfigurationGroup} from '../../../models/configuration-group.model';
import {Terminal} from 'src/app/models/terminal.model';
import * as cloneDeep from 'lodash/cloneDeep';
import {CommonConfig} from '../../shared/config/common-config';
import {FileMetadata} from 'src/app/models/file-metadata.model';
import {ReloadDataService} from 'src/app/services/reload-data.service';
import {AdminLoginService} from '../../../services/admin-login.service';
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';
import Utils from '../../../utils/utils';
import {RowData} from '../../../models/rowdata.model';
import {catchError} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Component({
  selector: 'dms-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})

export class FileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() filterValue: string;

  @ViewChild('fileTable') private readonly fileTable: TableComponent;
  @ViewChild(InfiniteScrollDirective) private readonly infiniteScrollDirective: InfiniteScrollDirective;

  tableConfig: TableConfig;
  tableData: FileMetadata[] = [];
  terminals: Terminal[];
  configGroups: ConfigurationGroup[];
  commonConfig: CommonConfig;
  isWebView: boolean;
  @Input() startDateFilter: Date;
  @Input() endDateFilter: Date;
  @Input() pageNumber: number;
  isDeleteInProgress: boolean;
  private readonly stop$: Subject<void> = new Subject<void>();

  constructor(
    private readonly docMgmtService: DocMgmtService,
    private readonly rightPanelService: RightPanelService,
    private readonly handleErrorService: HandleErrorService,
    private readonly translateService: CustomTranslateService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly terminalService: TerminalService,
    private readonly reloadDataService: ReloadDataService,
    private readonly adminLoginService: AdminLoginService
  ) {
    this.updateWebViewOnScreenSizeChange();
  }

  ngOnInit(): void {
    this.handleCustomerSelectionChange();
    this.createCommonConfig();
    this.loadFileMetadataIfReloaded();
    this.buildTableConfig();
    this.subscribeToObservables();
  }

  private handleCustomerSelectionChange(): void {
    this.adminLoginService.customerSelectionChanged$
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        this.resetTable();
      });
  }

  private createCommonConfig(): void {
    if (!this.commonConfig) {
      this.commonConfig = new CommonConfig();
    }
  }

  private loadFileMetadataIfReloaded(): void {
    if (this.reloadDataService.getShouldReload()) {
      this.docMgmtService.getFileMetadata();
    }
  }

  private buildTableConfig(): void {
    this.tableConfig = new TableConfig();
    this.tableConfig.displayedColumnNames = [
      'fileName',
      'createdDate',
      'formattedFileSize',
    ];
    this.tableConfig.iconColumnName = 'fileName';
    this.tableConfig.showCheckbox = false;
    this.tableConfig.rowClickAction = true;
    this.tableConfig.infiniteScrollEnabled = true;
    this.tableConfig.pagination = false;
    this.tableConfig.showSearchBar = false;

    const downloadAction = Utils.buildDownloadAction();
    const previewAction = Utils.buildPreviewAction();
    const deleteAction = Utils.buildDeleteAction();

    this.tableConfig.actionMenuList = [
      deleteAction,
      downloadAction,
      previewAction
    ];
  }

  private updateWebViewOnScreenSizeChange(): void {
    this.breakpointObserver.observe(Breakpoints.XSmall)
      .pipe(takeUntil(this.stop$))
      .subscribe((result) => {
        this.isWebView = !result.matches;
      });
  }

  private subscribeToObservables(): void {
    this.subscribeToFileConfigPageNumber();
    this.subscribeToFileMetadata();
    this.subscribeToTerminals();
    this.subscribeToConfigurationGroups();
  }

  private subscribeToFileConfigPageNumber(): void {
    this.docMgmtService.fileConfigPageNumber.pipe(takeUntil(this.stop$)).subscribe((value) => {
      if (value) {
        this.commonConfig = new CommonConfig();
        this.commonConfig.searchFilterValue = this.filterValue;
      }
    });
  }

  private subscribeToFileMetadata(): void {
    this.docMgmtService.fileMetadata.pipe(takeUntil(this.stop$)).subscribe((fileData) => {
      this.processFileData(fileData);
    });
  }

  private subscribeToTerminals(): void {
    this.terminalService.terminals.pipe(takeUntil(this.stop$)).subscribe((value) => {
      if (value) {
        this.terminals = value;
      }
    });
  }

  private subscribeToConfigurationGroups(): void {
    this.terminalService.configurationGroups.pipe(takeUntil(this.stop$)).subscribe((value) => {
      if (value) {
        this.configGroups = value;
      }
    });
  }

  private resetTable(): void {
    this.setTableData(null);
    this.docMgmtService.changePageValue();
    this.infiniteScrollDirective.destroyScroller();
    this.infiniteScrollDirective.setup();
  }

  private processFileData(fileData: FileMetadata[]): void {
    if (fileData !== null) {
      fileData = fileData.map((file: FileMetadata) => {
        file.canPreview = Utils.isPreviewable(file.fileType.toLowerCase());
        return file;
      });
      if (!this.getTableData()) {
        this.setTableData(fileData);
      } else if (this.commonConfig.pageNumber === 0 || this.isDeleteInProgress) {
        this.setTableData(cloneDeep(fileData));
      } else {
        fileData.forEach((row) => {
          this.setTableData([...this.getTableData(), row]);
        });
        this.setTableData(cloneDeep(this.getTableData()));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createCommonConfig();
    this.setConfigurationOnChange();
    if (this.hasFirstFilterValueChanged(changes)) {
      this.setCommonConfigConfiguration(changes);
    }
  }

  private setConfigurationOnChange(): void {
    this.commonConfig.startDate = this.startDateFilter;
    this.commonConfig.endDate = this.endDateFilter;
    this.commonConfig.pageNumber = this.pageNumber ? this.pageNumber : 0;
  }

  private hasFirstFilterValueChanged(changes: SimpleChanges): boolean {
    return changes.filterValue && !changes.filterValue?.firstChange;
  }

  private setCommonConfigConfiguration(changes: SimpleChanges): void {
    this.commonConfig.pageNumber = 0;
    this.commonConfig.searchFilterValue = changes.filterValue.currentValue;
    this.docMgmtService.getFileMetadata({
      filterValue: this.commonConfig.searchFilterValue,
      startDate: this.commonConfig.startDate?.getTime(),
      endDate: this.commonConfig.endDate?.getTime(),
    });
  }

  ngOnDestroy() {
    this.stopSubscriptions();
  }

  private stopSubscriptions(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  rowSelected(row) {
    if (row) {
      this.rightPanelService.selectedFile.next(row);
    }
  }

  clearTableSelection() {
    if (this.fileTable) {
      this.fileTable.clearSelection();
    }
  }

  protected gridAction(data: { type: string; row: RowData }): void {
    switch (data?.type) {
      case Utils.ACTION_DOWNLOAD:
        Utils.handleDownload$(
          this.docMgmtService.downloadFile$(data.row.fileKey),
          data.row.fileName
        ).pipe(
          takeUntil(this.stop$),
          catchError(() => {
            this.handleErrorService.displayErrorDialog(
              this.translateService.getTranslatedValue('downloadUnavailable'),
              ''
            );
            return EMPTY;
          })
        ).subscribe(() => this.fileTable.onActionComplete());
        break;
      case Utils.ACTION_PREVIEW:
        Utils.handlePreview$(
          this.docMgmtService.downloadFile$(data.row.fileKey),
          data.row.fileName
        ).pipe(
          takeUntil(this.stop$),
          catchError(() => {
            this.handleErrorService.displayErrorDialog(
              this.translateService.getTranslatedValue('previewUnavailable'),
              ''
            );
            return EMPTY;
          })
        ).subscribe(() => this.fileTable.onActionComplete());
        break;
      case Utils.ACTION_DELETE:
        this.handleDeleteFile(data.row.fileKey);
        break;
    }
  }

  private handleDeleteFile(fileKey: string): void {
    this.docMgmtService
      .getAssignDetails(fileKey, null, null)
      .pipe(
        takeUntil(this.stop$),
        switchMap((links: Link[]) => {
          if (links.length === 0) {
            return this.docMgmtService.deleteFile$(fileKey);
          } else {
            this.handleErrorService.displayErrorDialog(
              this.translateService.getTranslatedValue('deleteError'),
              ''
            );
            return EMPTY;
          }
        }),
        catchError(() => {
          this.handleErrorService.displayErrorDialog(
            this.translateService.getTranslatedValue('deleteUnavailable'),
            ''
          );
          return EMPTY;
        })
      )
      .subscribe({
        next: (response) => {
          if (response !== undefined) {
            this.updateFileDataAfterDelete(fileKey);
          } else {
            this.handleDeleteError();
          }
        },
        error: () => this.handleDeleteError()
      });
  }

  private handleDeleteError(): void {
    this.handleErrorService.displayErrorDialog(
      this.translateService.getTranslatedValue('deleteErrorServer'),
      ''
    );
    this.setIsDeleteInProgress(false);
  }

  private updateFileDataAfterDelete(fileKey: string): void {
    this.setIsDeleteInProgress(true);
    const existingFileData = this.getTableData();
    this.closePanelAndClearSelection(fileKey);
    const updatedFileData = this.removeFileByFileKey(existingFileData, fileKey);
    this.docMgmtService.fileMetadata.next(updatedFileData);
    this.updateFullFileMetadata(fileKey);
    this.setIsDeleteInProgress(false);
  }

  private closePanelAndClearSelection(fileKey: string): void {
    if (this.rightPanelService.selectedFile.getValue()?.fileKey === fileKey) {
      this.rightPanelService.closePanel();
      this.clearTableSelection();
    }
  }

  private removeFileByFileKey(files: FileMetadata[], fileKey: string): FileMetadata[] {
    const removeIndex = files.findIndex(item => item.fileKey === fileKey);
    if (removeIndex !== -1) {
      files.splice(removeIndex, 1);
    }
    return files;
  }

  private updateFullFileMetadata(fileKey: string): void {
    const allFilesData = this.docMgmtService.fullFileMetadata.value;
    const updatedAllFileData = this.removeFileByFileKey(allFilesData, fileKey);
    this.docMgmtService.fullFileMetadata.next(updatedAllFileData);
  }

  private getTableData(): FileMetadata[] {
    return this.tableData;
  }

  private setTableData(value: FileMetadata[]) {
    this.tableData = value;
  }

  private setIsDeleteInProgress(value: boolean) {
    this.isDeleteInProgress = value;
  }
}
