<div class="grid-filter" *ngIf="filterForm">

  <form [formGroup]="filterForm">
    <mat-error *ngIf="filterForm.errors?.startDateGreaterThanEndDate">
      {{ "startDateGreaterThanEndDate" | translate }}
    </mat-error>

    <mat-error *ngIf="filterForm.errors?.timeExceed">
      {{ "timeExceed" | translate }}
    </mat-error>

    <mat-error *ngIf="filterForm.errors?.startDateNotSelected">
      {{ "startDateNotSelected" | translate }}
    </mat-error>

    <div class="filter-container">

      <span class="search-span">

        <modus-text-input
          #searchInput
          label="{{ 'search' | translate }}"
          type="text"
          include-search-icon
          class="icon-size"
          (mousedown)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
          placeholder="{{ 'fileDisplayName' | translate }}"
          (valueChange)="onSearch(searchInput.value)"
          clearable="true"
          value={{filterValue}}
        >
        </modus-text-input>

      </span>

      <ng-container *ngIf="isWebView; else filterButton">

      <span class="datepicker-span-start">

        <modus-date-picker>
          <modus-date-input
            #startDateInput
            type="start"
            label="{{ 'startDate' | translate }}"
            format="dd-mm-yyyy"
            allowed-chars-regex="[\d-]"
            show-calendar-icon="true"
            placeholder="{{ 'selectStartDate' | translate }}"
            (valueChange)="onStartDateChanged(startDateInput.value)"
          ></modus-date-input>
        </modus-date-picker>

      </span>

        <span class="datepicker-span">

        <modus-date-picker>
          <modus-date-input
            #endDateInput
            type="end"
            label="{{ 'endDate' | translate }}"
            format="dd-mm-yyyy"
            allowed-chars-regex="[\d-]"
            show-calendar-icon="true"
            placeholder="{{ 'selectEndDate' | translate }}"
            (valueChange)="onEndDateChanged(endDateInput.value)"
          ></modus-date-input>
        </modus-date-picker>

      </span>

        <modus-button
          class="reset-style"
          (buttonClick)="onResetClick()"
          button-style="outline"
          color="primary"
        >
          {{ "reset" | translate }}
        </modus-button>

      </ng-container>

      <ng-template #filterButton>

      <span class="filter-style">
        <button class="filter-button" (click)="filter()">
          <img
            src="../../../../assets/images/filter.svg"
            class="filter-img"
            alt="Filter"
          />
        </button>
      </span>

      </ng-template>

    </div>
  </form>
</div>

<dms-table
  [tableConfig]="tableConfig"
  [tableData]="tableData"
  [isWebView]="isWebView"
  (action)="gridAction($event)"
  #incomingDocumentsTable
>
</dms-table>
