<div class="row">
    <div class="toastContent">
        <span *ngIf="options.toastClass.indexOf('red') > -1">Error: </span>
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">
            {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" class="custom-message"
            [class]="options.messageClass" [innerHTML]="message">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="custom-message"
            [class]="options.messageClass" [attr.aria-label]="message">
            {{ message }}
        </div>
    </div>
    <div class="text-right">
        <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm toast-close-button">
            &times;
        </a>
    </div>
</div>
