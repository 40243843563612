<div
  class="container"
  [ngClass]="{ 'custom-search-field': tableConfig.showCustomSearchField }"
>
  <span class="search-span">
    <modus-text-input
      label="Search"
      *ngIf="tableConfig.showSearchBar"
      type="text"
      include-search-icon
      class="icon-size"
      (mousedown)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
      (keyup)="applyFilter($event)"
      placeholder="{{ 'search' | translate }}"
    >
    </modus-text-input>
  </span>

  <span class="datepicker-span" *ngIf="tableConfig.showStartDatePicker">
  <modus-date-picker>
    <modus-date-input
      type="start"
      label="Start date"
      format="dd-mm-yyyy"
      show-calendar-icon="true"
      placeholder="{{ 'selectStartDate' | translate }}"></modus-date-input>
  </modus-date-picker>
</span>

  <span class="datepicker-span" *ngIf="tableConfig.showEndDatePicker">
  <modus-date-picker>
    <modus-date-input
      type="end"
      label="End date"
      format="dd-mm-yyyy"
      show-calendar-icon="true"
      placeholder="{{ 'selectEndDate' | translate }}"></modus-date-input>
  </modus-date-picker>
</span>
</div>
<div class="table-container">
  <div class="table-pagination">
    <div
      class="table-content"
      [ngStyle]="{ 'height.%': tableConfig.contentHeight }"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll($event)"
      [scrollWindow]="false"
      #tableComponent
    >
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        multiTemplateDataRows
        class="mat-elevation-z8"
        (matSortChange)="pageDataChange()"
        [ngClass]="{
          'table-responsive': !isWebView
        }"
      >
        <ng-container
          *ngIf="tableConfig.showCheckbox"
          matColumnDef="commonCheckBoxSelect"
        >
          <th mat-header-cell *matHeaderCellDef>
            <ng-container *ngIf="tableConfig.headerCheckbox">
              <modus-checkbox
                (checkboxClick)="checkBoxHeaderEvent($event)"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </modus-checkbox>
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let row" style="padding: 10px !important">
            <modus-checkbox
              (checkboxClick)="checkBoxEvent($event, row, selection)"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row) && !row?.isCheckboxDisabled"
              [aria-label]="checkboxLabel()"
              [disabled]="row?.isCheckboxDisabled"
            >
            </modus-checkbox>
          </td>
        </ng-container>

        <ng-container
          *ngFor="
            let columnName of tableConfig.displayedColumnNames;
            let i = index
          "
        >
          <ng-container
            *ngIf="
              columnName != 'commonCheckBoxSelect' &&
              columnName != 'actionColumn'
            "
            matColumnDef="{{ columnName }}"
          >
            <th
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              [ngClass]="{
                rightAlignValue:
                  tableConfig.rightAlignColumnName.indexOf(columnName) > -1
              }"
            >
              {{ columnName | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{
                rightAlignValue:
                  tableConfig.rightAlignColumnName.indexOf(columnName) > -1
              }"
              title="{{ element[columnName] }}"
            >
              <ng-container
                *ngIf="
                  tableConfig.iconColumnName &&
                    columnName == tableConfig.iconColumnName &&
                    element['iconName'];
                  else textColumn
                "
              >
                <span class="iconSize">
                  <i class="modus-icons" aria-hidden="true">{{
                      element["iconName"]
                    }}</i>
                </span>
                <span class="iconColumn">
                  {{ element[columnName] }}
                </span>
              </ng-container>
              <ng-template #textColumn>
                {{ element[columnName] }}
              </ng-template>
            </td>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="tableConfig.actionMenuList.length > 0"
          matColumnDef="actionColumn"
        >
          <ng-container *ngIf="isWebView; else actionMenu">
            <th
              mat-header-cell
              *matHeaderCellDef
              [ngStyle]="{ 'width.px': tableConfig.actionMenuList.length * 50 }"
            ></th>
            <td mat-cell *matCellDef="let row">
              <ng-container
                *ngIf="actionInProgress && row === selectedRowId; else actionList"
              >
                <modus-spinner size="1rem" class="table-spinner"></modus-spinner>
              </ng-container>
              <ng-template #actionList>
                <ng-container
                  *ngFor="let actionItem of tableConfig.actionMenuList"
                >
                  <ng-container
                    *ngIf="
                    actionItem['menuName'] === 'Preview' && !row.canPreview;
                    else defaultItem
                  "
                  >
                    <span class="actionIcon" (click)="$event.stopPropagation()">
                      <i
                        class="modus-icons disabled-preview-icon"
                        aria-hidden="true"
                      >{{ actionItem["iconName"] }}</i>
                    </span>
                  </ng-container>
                  <ng-template #defaultItem>
                  <span
                    class="actionIcon"
                    title="{{ actionItem['menuName'] | translate }}"
                    (click)="
                      $event.stopPropagation();
                      doAction(row, actionItem['menuName'])
                    "
                  >
                    <i
                      class="modus-icons"
                      aria-hidden="true"
                      [ngStyle]="{ color: actionItem['iconColor'] }"
                    >{{ actionItem["iconName"] }}</i
                    >
                  </span>
                  </ng-template>
                </ng-container>
              </ng-template>
            </td>
          </ng-container>
          <ng-template #actionMenu>
            <th mat-header-cell
                *matHeaderCellDef
                [ngStyle]="{ 'width.px': 50 }"></th>
            <td mat-cell *matCellDef="let row">
              <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="mainMenu" (click)="$event.stopPropagation()">
                  <i class="modus-icons" aria-hidden="true">more_vertical</i>
                </button>

                <mat-menu #mainMenu="matMenu">

                  <ng-container *ngFor="let actionItem of tableConfig.actionMenuList">

                    <ng-container *ngIf="actionItem['menuName'] !== 'Preview' || row.canPreview">

                      <button
                        mat-menu-item
                        (click)="$event.stopPropagation();
                      doAction(row, actionItem['menuName'])">

                        <i
                          class="modus-icons"
                          aria-hidden="true"
                          [ngStyle]="{ color: actionItem['iconColor'] }"
                        >
                          {{ actionItem["iconName"] }}
                        </i>

                        <span class="menu-content">
                        {{ actionItem['menuName'] }}
                      </span>

                      </button>

                    </ng-container>

                  </ng-container>

                </mat-menu>

              </ng-container>
            </td>
          </ng-template>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="
            tableConfig.displayedColumnNames;
            sticky: tableConfig.stickyHeader
          "
        ></tr>
        <ng-container
          *ngIf="
            tableConfig.rowClickAction &&
            tableConfig.contextMenuDetails.length > 0
          "
        >
          <tr
            (contextmenu)="onContextMenu($event, element)"
            mat-row
            *matRowDef="let element; columns: tableConfig.displayedColumnNames"
            [ngClass]="
              selection.isSelected(element)
                ? 'selectedColor pointer'
                : 'hoverColor pointer'
            "
            (click)="rowOnClick(element)"
          ></tr>
        </ng-container>
        <ng-container
          *ngIf="
            !tableConfig.rowClickAction &&
            tableConfig.contextMenuDetails.length > 0
          "
        >
          <tr
            (contextmenu)="onContextMenu($event, element)"
            mat-row
            *matRowDef="let element; columns: tableConfig.displayedColumnNames"
            [ngClass]="
              selection.isSelected(element) ? 'selectedColor' : 'hoverColor'
            "
          ></tr>
        </ng-container>
        <ng-container
          *ngIf="
            tableConfig.rowClickAction &&
            tableConfig.contextMenuDetails.length === 0
          "
        >
          <tr
            mat-row
            *matRowDef="let element; columns: tableConfig.displayedColumnNames"
            [ngClass]="
              selection.isSelected(element)
                ? 'selectedColor pointer'
                : 'hoverColor pointer'
            "
            (click)="rowOnClick(element)"
          ></tr>
        </ng-container>
        <ng-container
          *ngIf="
            !tableConfig.rowClickAction &&
            tableConfig.contextMenuDetails.length === 0
          "
        >
          <tr
            mat-row
            *matRowDef="let element; columns: tableConfig.displayedColumnNames"
            [ngClass]="
              selection.isSelected(element) ? 'selectedColor' : 'hoverColor'
            "
          ></tr>
        </ng-container>
      </table>
      <div *ngIf="!tableData" class="spinner-style">
        <modus-spinner size="5rem"></modus-spinner>
      </div>
      <div *ngIf="tableData && tableData.length < 1" class="noItem">
        {{ "noData" | translate }}
      </div>
    </div>
    <ng-container *ngIf="tableConfig.pagination">
      <ng-container
        *ngIf="
          tableConfig.showPaginationFirstLastButtons;
          else noShowFirstLastButtons
        "
      >
        <mat-paginator
          [pageSizeOptions]="tableConfig.pageSizeOptions"
          (page)="pageDataChange()"
          showFirstLastButtons
        ></mat-paginator>
      </ng-container>
      <ng-template #noShowFirstLastButtons>
        <mat-paginator
          [pageSizeOptions]="tableConfig.pageSizeOptions"
          (page)="pageDataChange()"
        >
        </mat-paginator>
      </ng-template>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="tableConfig.contextMenuDetails.length > 0">
  <div
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu"
  ></div>
  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="item">
      <ng-container *ngFor="let menu of tableConfig.contextMenuDetails">
        <button
          mat-menu-item
          (click)="onContextMenuAction(item, menu.menuName)"
        >
          <i
            class="modus-icons"
            [ngStyle]="{ color: menu['iconColor'] }"
            aria-hidden="true"
          >{{ actionItem["iconName"] }}</i
          >
          <span style="margin-left: 20px; font-size: 14px">{{
              menu.menuName | translate
            }}</span>
        </button>
      </ng-container>
    </ng-template>
  </mat-menu>
</ng-container>
