import {IncomingDocumentsComponent} from './incoming-documents/incoming-documents.component';
import {TerminalService} from '../../services/terminal.service';
import {Subject, takeUntil} from 'rxjs';
import {DocMgmtService} from '../../services/doc-mgmt.service';
import {GroupComponent} from './group/group.component';
import {UnitComponent} from './unit/unit.component';
import {FileComponent} from './file/file.component';
import {RightPanelService} from '../../services/right-panel.service';
import {UserService} from '../../services/user.service';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {FileMetadata} from '../../models/file-metadata.model';
import Utils from '../../utils/utils';
import {CustomTranslateService} from '../../services/custom-translate.service';
import {TidUserProfile} from '../../models/user-profile.model';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateRangeValidator, END_DATE_KEY, START_DATE_KEY} from '../shared/date-range-validator';
import {CommonConfig} from '../shared/config/common-config';
import {TranslateService} from '@ngx-translate/core';
import {AdminLoginService} from '../../services/admin-login.service';
import {CustomerService} from '../../services/customer.service';
import {Customer} from '../../models/customer.model';

@Component({
  selector: 'document-dashboard',
  templateUrl: './document-dashboard.component.html',
  styleUrls: ['./document-dashboard.component.scss'],
})
export class DocumentDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('panel', {static: false}) set setPanelView(
    panelView: MatSidenav
  ) {
    if (panelView) {
      this.rightPanelService.setRightPanelInstance(panelView);
    }
  }

  @ViewChild('fileView', {static: false}) set setFileView(
    fileView: FileComponent
  ) {
    if (fileView) {
      this.fileView = fileView;
    }
  }

  @ViewChild('unitView', {static: false}) set setUnitView(
    unitView: UnitComponent
  ) {
    if (unitView) {
      this.unitView = unitView;
    }
  }

  @ViewChild('groupView', {static: false}) set setGroupView(
    groupView: GroupComponent
  ) {
    if (groupView) {
      this.groupView = groupView;
    }
  }

  @ViewChild('incomingDocumentsView', {static: false})
  set setIncomingDocumentsView(
    incomingDocumentsView: IncomingDocumentsComponent
  ) {
    if (incomingDocumentsView) {
      this.incomingDocumentsView = incomingDocumentsView;
    }
  }

  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
  @ViewChild('startDateInput') startDateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;

  fileView: FileComponent;
  unitView: UnitComponent;
  groupView: GroupComponent;
  incomingDocumentsView: IncomingDocumentsComponent;
  selectedTab = 0;
  disableTab = true;
  showDialog = false;
  files = [];
  minimized: boolean;
  dialogHeight = 200;
  failedCount = 0;
  successCount = 0;
  uploadedCount = 0;
  maxFileSize: number;
  maxFileSizeInMb: number;
  uploadedFiles: FileMetadata[] = [];
  uploadSuccess: boolean;
  userDetailesLoaded = false;
  isWebView: boolean;
  showFilterPage = false;
  headerPosition = 'above';
  filterForm: FormGroup;
  commonConfig: CommonConfig;
  filterValue: string;
  startDateFilter: Date;
  endDateFilter: Date;
  pageNumber: number;
  searchFilterValue = '';
  isIncomingDocTab: boolean;
  INCOMINGDOC_TAB_INDEX = 3;
  errorWithSizeTranslation: string;

  private readonly stop$: Subject<void> = new Subject<void>();
  private readonly DEFAULT_FILE_SIZE = 10000;
  private readonly KB_TO_MB = 1024;
  protected customerNotSelected: boolean;
  private inProgress = false;

  constructor(
    private userService: UserService,
    private rightPanelService: RightPanelService,
    private docMgmtService: DocMgmtService,
    private readonly translateService: CustomTranslateService,
    private readonly terminalService: TerminalService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly fb: FormBuilder,
    private translate: TranslateService,
    private readonly adminLoginService: AdminLoginService,
    private readonly customerService: CustomerService
  ) {
    this.setScreenSize();
    this.commonConfig = new CommonConfig();
    translate.addLangs(['en', 'klingon']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit(): void {
    this.subscribeToCustomerSelectionChange();
    this.initializeForm();
    this.loadCustomerDetails();

    if (!this.docMgmtService.fullFileMetadata.value) {
      this.docMgmtService.getFullFileMetadata();
    }

    this.docMgmtService.disableTab
      .pipe(takeUntil(this.stop$))
      .subscribe((value) => {
        this.disableTab = value;
      });

  }

  private subscribeToCustomerSelectionChange(): void {
    this.adminLoginService.customerSelectionChanged$
      .pipe(takeUntil(this.stop$))
      .subscribe((isSelected) => {
        this.setCustomerNotSelected(!isSelected);
        if (this.customerNotSelected === false) {
          this.docMgmtService.fullFileMetadata.next([]);
          this.loadCustomerDetails();
          this.docMgmtService.getFileMetadata({
            filterValue: this.filterValue
          });
          this.docMgmtService.getFullFileMetadata();
        }
      });
  }

  private loadCustomerDetails = (): void => {
    this.customerService
      .getCustomerDetail$()
      .pipe(takeUntil(this.stop$))
      .subscribe((customer: Customer) => {
        this.setFileSizeAndLoadTable(customer);
      });
  }

  private setFileSizeAndLoadTable = (customer: Customer): void => {
    const maxDocSize = customer.maxDocSize || this.DEFAULT_FILE_SIZE;
    this.maxFileSizeInMb = Math.round(maxDocSize / this.KB_TO_MB);
    this.maxFileSize = maxDocSize * this.KB_TO_MB;
    this.translateAndSetErrorWithSize();
    this.loadAllTable(this.userService.getTidUserProfile());
  }

  private translateAndSetErrorWithSize(): void {
    this.translate.get('alert', {size: this.maxFileSizeInMb})
      .pipe(takeUntil(this.stop$))
      .subscribe(translatedMessage => {
        this.errorWithSizeTranslation = translatedMessage;
      });
  }

  private setCustomerNotSelected(value: boolean): void {
    this.customerNotSelected = value;
  }

  initializeForm(): void {
    this.filterForm = this.fb.group(
      {
        startDate: [null],
        endDate: [null],
      },
      {validator: DateRangeValidator.dateRangeValidator(this.commonConfig)}
    );
    this.searchFilterValue = this.filterValue;
  }

  setScreenSize() {
    this.breakpointObserver.observe(Breakpoints.XSmall).subscribe((result) => {
      if (result.matches) {
        this.isWebView = false;
        this.headerPosition = 'below';
      } else {
        this.isWebView = true;
        this.headerPosition = 'above';
      }
    });
  }

  refresh() {
    if (this.selectedTab === 0) {
      this.resetAllFileFilters();
    }
    if (this.selectedTab === 1) {
      this.terminalService.getTerminals();
    }
    if (this.selectedTab === 2) {
      this.terminalService.getTerminals();
    }
    if (this.selectedTab === 3) {
      this.incomingDocumentsView.filterValue = '';
      this.incomingDocumentsView.onResetClick();
    }
  }

  resetAllFileFilters() {
    this.filterValue = '';
    this.onResetClick();
  }

  loadAllTable(tidUserProfile: TidUserProfile) {
    this.translateService
      .setLanguageTranslation(tidUserProfile?.language)
      .then(() => {
        this.userDetailesLoaded = true;
      });
  }

  onTabChange(tab) {
    this.selectedTab = tab?.index;
    if (this.selectedTab === this.INCOMINGDOC_TAB_INDEX) {
      this.closePanel();
    }
  }

  closePanel() {
    this.rightPanelService.closePanel();
    this.fileView.clearTableSelection();
    this.unitView.clearTableSelection();
    this.groupView.clearTableSelection();
    this.rightPanelService.selectedFile.next(null);
    this.rightPanelService.selectedUnit.next(null);
    this.rightPanelService.selectedGroup.next(null);
  }

  ngOnDestroy() {
    this.stopSubscriptions();
    this.rightPanelService.selectedFile.next(null);
    this.rightPanelService.selectedGroup.next(null);
    this.rightPanelService.selectedUnit.next(null);
  }

  private stopSubscriptions(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  closeProgresssDialog() {
    this.showDialog = false;
  }

  minimizeDialog() {
    this.minimized = !this.minimized;
    this.minimized ? (this.dialogHeight = 0) : (this.dialogHeight = 200);
  }

  onUploadClick() {
    this.files = [];
    this.uploadedFiles = [];
    this.uploadedCount = 0;
    this.failedCount = 0;
    this.successCount = 0;
    this.uploadSuccess = false;
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({data: file, progress: 0});
      }
      if (this.files.length > 0) {
        this.showDialog = true;
        this.setInProgress(true);
        this.uploadFiles();
      }
    };
    fileUpload.click();
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach((file) => {
      if (file.data.size < this.maxFileSize) {
        this.uploadFile(file);
      } else {
        file.alert = true;
        this.uploadedCount = this.uploadedCount + 1;
        this.failedCount = this.failedCount + 1;
        if (this.uploadedCount === this.files.length) {
          this.setInProgress(false);
        }
      }
    });
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    this.docMgmtService
      .upload(formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.uploadedCount = this.uploadedCount + 1;
          this.failedCount = this.failedCount + 1;
          file.failure = true;
          if (this.uploadedCount === this.files.length) {
            this.loadFiles(this.uploadedFiles);
            this.setInProgress(false);
          }
          return `${file.data.name} upload failed.`;
        })
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.uploadedCount = this.uploadedCount + 1;
          this.successCount = this.successCount + 1;
          file.success = true;
          const uploadedFile: FileMetadata = new FileMetadata();
          uploadedFile.fileKey = event.body.fileId;
          uploadedFile.fileType = file.data.type;
          uploadedFile.fileName = file.data.name;
          uploadedFile.createdDate = new Date().getTime().toString();
          uploadedFile.fileSize = file.data.size;
          uploadedFile.formattedFileSize = Utils.bytesToSize(file.data.size);
          this.uploadedFiles.push(uploadedFile);
          if (this.uploadedCount === this.files.length) {
            this.loadFiles(this.uploadedFiles);
            this.setInProgress(false);
          }
          if (
            this.uploadedCount === this.files.length &&
            this.failedCount === 0
          ) {
            this.uploadSuccess = true;
          }
        }
      });
  }

  loadFiles(uploadedFiles) {
    this.resetAllFileFilters();
    let formattedFiles = Utils.formatFiles(uploadedFiles);
    let fileData = this.docMgmtService.fullFileMetadata.value;
    let newFileList = formattedFiles.concat(fileData);
    this.docMgmtService.fullFileMetadata.next(newFileList);
  }

  filter() {
    this.showFilterPage = true;
    this.isIncomingDocTab = false;
  }

  closeFilterPage(event: number) {
    this.showFilterPage = false;
    this.selectedTab = event;
  }

  filterValueEvent(event): void {
    this.startDateFilter = event.startDate;
    this.endDateFilter = event.endDate;
    this.pageNumber = event.pageNumber;
    this.searchFilterValue = event.filterValue;
  }

  onStartDateChanged(startDateValue: Date): void {
    this.filterForm.get(START_DATE_KEY).setValue(startDateValue);
    const endDateValue = this.filterForm.get(END_DATE_KEY).value;
    if (this.filterForm.errors == null) {
      this.setDocMgmtServiceOnDatesChange(startDateValue, endDateValue);
    }
  }

  onEndDateChanged(endDateValue: Date): void {
    this.filterForm.get(END_DATE_KEY).setValue(endDateValue);
    const startDateValue = this.filterForm.get(START_DATE_KEY).value;
    if (this.filterForm.errors == null) {
      this.setDocMgmtServiceOnDatesChange(startDateValue, endDateValue);
    }
  }

  setDocMgmtServiceOnDatesChange(
    startDateValue?: Date,
    endDateValue?: Date
  ): void {
    if (startDateValue && endDateValue) {
      this.setDocMgmtServiceOnBothDatesPresent();
      this.startDateFilter = this.commonConfig.startDate;
      this.endDateFilter = this.commonConfig.endDate;
      this.pageNumber = 0;
    } else if (!startDateValue && !endDateValue) {
      this.setDocMgmtServiceOnBothDatesEmpty();
    }
  }

  setDocMgmtServiceOnBothDatesPresent(): void {
    this.docMgmtService.getFileMetadata({
      page: 0,
      filterValue: this.filterValue,
      startDate: this.commonConfig.startDate?.getTime(),
      endDate: this.commonConfig.endDate?.getTime(),
    });
  }

  setDocMgmtServiceOnBothDatesEmpty(): void {
    this.docMgmtService.changePageValue();
    this.docMgmtService.getFileMetadata({filterValue: this.filterValue});
  }

  onResetClick(): void {
    this.docMgmtService.changePageValue();
    this.initializeForm();
    if (this.startDateInput && this.startDateInput.nativeElement) {
      this.startDateInput.nativeElement.value = undefined;
    }
    if (this.endDateInput && this.endDateInput.nativeElement) {
      this.endDateInput.nativeElement.value = undefined;
    }
    this.startDateFilter = undefined;
    this.endDateFilter = undefined;
    this.docMgmtService.getFileMetadata({filterValue: this.filterValue});
  }

  applyFilter(value) {
    this.filterValue = value?.trim()?.toLowerCase();
  }

  setFilterPage(event) {
    this.showFilterPage = event;
    this.isIncomingDocTab = true;
  }

  protected getInProgress(): boolean {
    return this.inProgress;
  }

  protected setInProgress(value: boolean): void {
    this.inProgress = value;
  }
}
