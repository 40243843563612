import {Driver} from '../models/driver.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {DMSConfigService} from './dmsconfig.service';

@Injectable()
export class DriverService {
  drivers = new BehaviorSubject<Driver[]>([]);

  constructor(
    private readonly http: HttpClient,
    private readonly dmsConfigService: DMSConfigService) {
  }

  getDriversDetail() {
    this.http
      .get(`${this.dmsConfigService.getConfig().API_DMS_GATEWAY_URL}/drivers`)
      .subscribe((drivers: Driver[]) => {
        this.drivers.next(drivers);
      });
  }
}
